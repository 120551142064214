import React, { useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { getProject } from "../../actions/projects";
import Spinner from "../../layout/Spinner";
import SingleItem from "../../components/template/SingleItem";
import { Link } from "react-router-dom";
import { URI } from "../../constants/constants";
const ViewProject = ({ getProject, match, project: { loading, project } }) => {
  useEffect(() => {
    getProject(match.params.id);
  }, [match]);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title="Projects"
          mainLinkTitle="Projects"
          mainLinkUrl="/projects"
          activeLink="View"
        />
        {!loading ? (
          project && (
            <div className="container-fluid">
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        {project.title} <span>View</span>
                      </h4>
                      <p className="card-title-desc">
                        <Link to="/projects" className="btn btn-soft-light">
                          <i className="fa fa-angle-left"></i> Projects
                        </Link>
                        <Link
                          to={`/projects/${project._id}/edit`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-edit"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="card-body">
                    <SingleItem title="title" value={project.title} />
                    <SingleItem title="goal" value={project.goal} />
                    <SingleItem title="raised" value={project.raised} />
                    <SingleItem
                      title="description"
                      value={project.description}
                    />
                    {project.featured_image && (
                      <SingleItem title="Featured Image">
                        <img
                          src={`${URI}${project.featured_image.url}`}
                          style={{
                            width: "200px",
                            height: "200px",
                            objectFit: "contain",
                          }}
                        />
                      </SingleItem>
                    )}
                    {project.gallery && project.gallery.length > 0 && (
                      <SingleItem title="Gallery">
                        <div className="d-flex" style={{ flexWrap: "wrap" }}>
                          {project.gallery.map((item) => {
                            return (
                              <img
                                src={`${URI}${item.url}`}
                                style={{
                                  width: "80px",
                                  height: "80px",
                                  objectFit: "contain",
                                }}
                              />
                            );
                          })}
                        </div>
                      </SingleItem>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ project: state.project });

const mapDispatchToProps = { getProject };

export default connect(mapStateToProps, mapDispatchToProps)(ViewProject);
