import React, { useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Form, Formik } from "formik";
import { getNewsletter, editNewsletter } from "../../actions/newsletters";
import * as Yup from "yup";
import {
  CheckBox,
  SelectBox,
  TextArea,
  TextInput,
} from "../../components/Form/Form";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Spinner from "../../layout/Spinner";

const EditNewsletter = ({
  editNewsletter,
  getNewsletter,
  alert,
  match,
  newsletter: { loading, newsletter },
}) => {
  let history = useHistory();
  useEffect(() => {
    getNewsletter(match.params.id);
  }, [match]);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title="Edit Newsletter"
          mainLinkTitle="Newsletters"
          mainLinkUrl="/newsletters"
          activeLink="Edit"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            {!loading ? (
              newsletter && (
                <div>
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        {newsletter.email} - <span>Edit</span>{" "}
                      </h4>
                      <p className="card-title-desc">
                        <Link to="/newsletters" className="btn btn-soft-light">
                          <i className="fa fa-angle-left"></i> Newsletters
                        </Link>
                        <Link
                          to={`/newsletters/${newsletter._id}/view`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <Formik
                        initialValues={{
                          email: newsletter.email,
                        }}
                        validationSchema={Yup.object({
                          email: Yup.string().required("Required"),
                        })}
                        onSubmit={async (
                          values,
                          { setSubmitting, resetForm }
                        ) => {
                          setSubmitting(true);
                          await editNewsletter(newsletter._id, values);
                          resetForm();
                          setSubmitting(false);
                          history.push(`/newsletters/${newsletter._id}/view`);
                        }}
                      >
                        {(formik) => {
                          console.log(formik);
                          return (
                            <Form autoComplete={"off"}>
                              <div className="row">
                                <div className="col-md-6">
                                  <TextInput
                                    label="email"
                                    name="email"
                                    type="text"
                                    placeholder="Enter email"
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12 text-center m-3">
                                  <button
                                    type="submit"
                                    className="btn btn-success"
                                  >
                                    {formik.isSubmitting
                                      ? "Processing..."
                                      : "Save"}
                                  </button>
                                  <a
                                    className="btn btn-secondary m-3"
                                    onClick={() => history.goBack()}
                                  >
                                    <i className="fa fa-angle-left"></i> Go Back
                                  </a>
                                </div>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              )
            ) : (
              <div className="text-center">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
  newsletter: state.newsletter,
});

const mapDispatchToProps = { editNewsletter, getNewsletter };

export default connect(mapStateToProps, mapDispatchToProps)(EditNewsletter);
