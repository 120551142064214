import React, { useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { getSuccessStorie } from "../../actions/success_stories";
import Spinner from "../../layout/Spinner";
import SingleItem from "../../components/template/SingleItem";
import { Link } from "react-router-dom";
import { URI } from "../../constants/constants";

const ViewSuccessStorie = ({ getSuccessStorie, match, success_storie: { loading, success_storie } }) => {
    useEffect(() => {
        getSuccessStorie(match.params.id);
    }, [match]);
    return (
        <div className="pace-done">
            <div>
                <Header />
                <BreadCrumb
                    title="SuccessStories"
                    mainLinkTitle="SuccessStories"
                    mainLinkUrl="/success_stories"
                    activeLink="View"
                />
                {!loading ? (
                    success_storie && (
                        <div className="container-fluid">
                            <div className="col-lg-6">
                                <div className="card">
                                    <div className="card-header">
                                        <div>
                                            <h4 className="card-title">
                                                {success_storie.name} <span>View</span>
                                            </h4>
                                            <p className="card-title-desc">
                                                <Link to="/success-stories" className="btn btn-soft-light">
                                                    <i className="fa fa-angle-left"></i> SuccessStories
                                                </Link>
                                                <Link
                                                    to={`/success-stories/${success_storie._id}/edit`}
                                                    className="btn btn-soft-light"
                                                >
                                                    <i className="fa fa-edit"></i>
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <SingleItem title="Name" value={success_storie.name} />
                                        {success_storie.images && (
                                            <SingleItem title="Featured Image">
                                                <img
                                                    src={`${URI}${success_storie.images && success_storie.images[0].url}`}
                                                    style={{
                                                        width: "200px",
                                                        height: "200px",
                                                        objectFit: "contain",
                                                    }}
                                                />
                                            </SingleItem>
                                        )}

                                        {success_storie.documents && success_storie.documents.length > 0 && (
                                            <SingleItem title="Documents">
                                                <div className="d-flex" style={{ flexWrap: "wrap" }}>
                                                    {success_storie.documents.map((item) => {
                                                        return (
                                                            <img
                                                                src={`${URI}${item.url}`}
                                                                style={{
                                                                    width: "80px",
                                                                    height: "80px",
                                                                    objectFit: "contain",
                                                                }}
                                                            />
                                                        );
                                                    })}
                                                </div>
                                            </SingleItem>
                                        )}
                                        <SingleItem title="Description" value={success_storie.description} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                ) : (
                    <div>
                        <Spinner />
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({ success_storie: state.success_storie });

const mapDispatchToProps = { getSuccessStorie };

export default connect(mapStateToProps, mapDispatchToProps)(ViewSuccessStorie);
