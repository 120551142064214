import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../components/template/BreadCrumb";
import Header from "../components/template/Header";
import { Link } from "react-router-dom";
import Spinner from "../layout/Spinner";
import {
  countDonation,
  getDonations,
  deleteDonation,
} from "../actions/donations";
import { countCause } from "../actions/causes";
import { countProject } from "../actions/projects";

import { useLocation } from "react-router-dom";
import Pagination from "../components/Pagination";
const AllDonations = ({
  countDonation,
  countCause,
  history,
  countProject,
  donation: { loading, donations, total_donations },
  cause: { total_causes },
  project: { total_projects },
}) => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const [page, setPage] = useState(null);
  const [limit, setLimit] = useState(null);
  const [sort, setSort] = useState(null);
  const [q, setQ] = useState(null);
  const [createdAt_lte, setCreatedAt_lte] = useState(null);
  const [createdAt_gte, setCreatedAt_gte] = useState(null);
  const [term, setTerm] = useState(query.get("_q"));
  const [deleteEntry, setDeleteEntry] = useState(null);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    const q = query.get("_q");
    const createdAt_lte = query.get("createdAt_lte");
    const createdAt_gte = query.get("createdAt_gte");
    countDonation({ q, createdAt_lte, createdAt_gte });
    countCause({ q: null });
    countProject({ q: null });
  }, [query.get("_q"), query.get("createdAt_lte"), query.get("createdAt_gte")]);

  const changeParams = ({ label, value }) => {
    query.set(`${label}`, value);
    history.push(`${window.location.pathname}?${query.toString()}`);
  };
  const removeParams = (label) => {
    query.delete(label);
    history.push(`${window.location.pathname}?${query.toString()}`);
  };
  const deleteDonationClicked = (id) => {
    setDeleteEntry(id);
  };
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title="Dashboard"
          mainLinkTitle="Dashboard"
          mainLinkUrl="/dashboard"
          activeLink="Main"
        />

        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="card"
                style={{ boxShadow: "rgb(227 233 243) 0px 2px 4px" }}
              >
                <div className="card-body">
                  <div>
                    <div className="dataTables_wrapper dt-bootstrap4 no-footer"></div>
                    <div className="row">
                      <div
                        className="filters"
                        style={{
                          padding: 10,
                          margin: 10,
                        }}
                      >
                        <div className="d-flex">
                          <div>
                            <i className="fa fa-filter" /> Active Filters
                          </div>
                          {q && (
                            <div
                              style={{
                                border: "1px solid rgba(253,98,94,.25)",
                                margin: "0px 10px",
                                padding: "5px 5px",
                                color: "rgba(253,98,94,1)",
                                backgroundColor: "#f1f1f1",
                              }}
                            >
                              Search Term:{" "}
                              <label style={{ paddingRight: "10px" }}>
                                {" "}
                                {q}{" "}
                              </label>
                              <button
                                className="btn-sm btn-close"
                                onClick={() => {
                                  setTerm(""), removeParams("_q");
                                }}
                                style={{
                                  fontSize: "12px",
                                  color: "rgba(253,98,94,1)",
                                }}
                              >
                                <i
                                  className="fa fa-close"
                                  style={{
                                    fontSize: "12px",
                                    color: "rgba(253,98,94,1)",
                                  }}
                                ></i>
                              </button>
                            </div>
                          )}
                          {createdAt_gte && (
                            <div
                              style={{
                                border: "1px solid rgba(253,98,94,.25)",
                                margin: "0px 10px",
                                padding: "5px 5px",
                                color: "rgba(253,98,94,1)",
                                backgroundColor: "#f1f1f1",
                              }}
                            >
                              From:{" "}
                              <label style={{ paddingRight: "10px" }}>
                                {" "}
                                {createdAt_gte}{" "}
                              </label>
                              <button
                                className="btn-sm btn-close"
                                onClick={() => {
                                  setCreatedAt_gte(""),
                                    removeParams("createdAt_gte");
                                }}
                                style={{
                                  fontSize: "12px",
                                  color: "rgba(253,98,94,1)",
                                }}
                              >
                                <i
                                  className="fa fa-close"
                                  style={{
                                    fontSize: "12px",
                                    color: "rgba(253,98,94,1)",
                                  }}
                                ></i>
                              </button>
                            </div>
                          )}
                          {createdAt_lte && (
                            <div
                              style={{
                                border: "1px solid rgba(253,98,94,.25)",
                                margin: "0px 10px",
                                padding: "5px 5px",
                                color: "rgba(253,98,94,1)",
                                backgroundColor: "#f1f1f1",
                              }}
                            >
                              To:{" "}
                              <label style={{ paddingRight: "10px" }}>
                                {" "}
                                {createdAt_lte}{" "}
                              </label>
                              <button
                                className="btn-sm btn-close"
                                onClick={() => {
                                  setCreatedAt_lte(""),
                                    removeParams("createdAt_lte");
                                }}
                                style={{
                                  fontSize: "12px",
                                  color: "rgba(253,98,94,1)",
                                }}
                              >
                                <i
                                  className="fa fa-close"
                                  style={{
                                    fontSize: "12px",
                                    color: "rgba(253,98,94,1)",
                                  }}
                                ></i>
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-9">
                        <div className="d-flex">
                          <div
                            style={{ paddingLeft: "20px" }}
                            className="dataTables_length"
                            id="DataTables_Table_0_length"
                          >
                            <label>
                              From
                              <input
                                type="date"
                                value={createdAt_gte}
                                onChange={(e) => {
                                  changeParams({
                                    label: "page",
                                    value: 1,
                                  });
                                  changeParams({
                                    label: "createdAt_gte",
                                    value: e.target.value,
                                  });
                                }}
                                className="form-control"
                              />
                            </label>
                          </div>
                          <div
                            style={{ paddingLeft: "20px" }}
                            className="dataTables_length"
                            id="DataTables_Table_0_length"
                          >
                            <label>
                              To
                              <input
                                type="date"
                                className="form-control"
                                value={createdAt_lte}
                                onChange={(e) => {
                                  changeParams({
                                    label: "page",
                                    value: 1,
                                  });
                                  changeParams({
                                    label: "createdAt_lte",
                                    value: e.target.value,
                                  });
                                }}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-3 ">
                        <div
                          id="DataTables_Table_0_filter"
                          className="dataTables_filter"
                        >
                          <div className="d-flex" style={{ float: "right" }}>
                            <div>
                              <input
                                type="search"
                                className="form-control form-control-sm"
                                aria-controls="DataTables_Table_0"
                                value={term}
                                onChange={(e) => setTerm(e.target.value)}
                              />
                            </div>
                            <div style={{ padding: "0px 10px" }}>
                              <button
                                type="submit"
                                className="btn btn-soft-light"
                                onClick={() => {
                                  changeParams({
                                    label: "page",
                                    value: 1,
                                  });
                                  changeParams({
                                    label: "_q",
                                    value: term,
                                  });
                                }}
                              >
                                <i className="fa fa-search"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <Link to="/donations">
                <div
                  className="card work-card"
                  style={{ background: "#e3ff8e" }}
                >
                  <div className="card-body">
                    <div className="card-title">
                      <h5>Donations</h5>
                    </div>
                    <div className="card-body">
                      {loading ? <Spinner /> : <h2> {total_donations} </h2>}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-2">
              <Link to="/causes">
                <div
                  className="card work-card"
                  style={{ background: "#a3ffe2" }}
                >
                  <div className="card-body">
                    <div className="card-title">
                      {" "}
                      <h5>Causes</h5>
                    </div>
                    <div className="card-body">
                      {loading ? <Spinner /> : <h1> {total_causes} </h1>}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-2">
              <Link to="/projects">
                <div
                  className="card work-card"
                  style={{ background: "#ffefd4" }}
                >
                  <div className="card-body">
                    <div className="card-title">
                      {" "}
                      <h5>Projects</h5>
                    </div>
                    <div className="card-body">
                      {loading ? <Spinner /> : <h1> {total_projects} </h1>}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  donation: state.donation,
  cause: state.cause,
  project: state.project,
});

const mapDispatchToProps = { countDonation, countCause, countProject };

export default connect(mapStateToProps, mapDispatchToProps)(AllDonations);
