import React, { useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { getTestimonial } from "../../actions/testimonials";
import Spinner from "../../layout/Spinner";
import SingleItem from "../../components/template/SingleItem";
import { Link } from "react-router-dom";
import { URI } from "../../constants/constants";
const ViewTestimonial = ({
  getTestimonial,
  match,
  testimonial: { loading, testimonial },
}) => {
  useEffect(() => {
    getTestimonial(match.params.id);
  }, [match]);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title="Testimonials"
          mainLinkTitle="Testimonials"
          mainLinkUrl="/testimonials"
          activeLink="View"
        />
        {!loading ? (
          testimonial && (
            <div className="container-fluid">
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        {testimonial.user} <span>View</span>
                      </h4>
                      <p className="card-title-desc">
                        <Link to="/testimonials" className="btn btn-soft-light">
                          <i className="fa fa-angle-left"></i> Testimonials
                        </Link>
                        <Link
                          to={`/testimonials/${testimonial._id}/edit`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-edit"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="card-body">
                    <SingleItem title="User" value={testimonial.user} />
                    <SingleItem
                      title="Testimonial"
                      value={testimonial.testimonial}
                    />
                    {testimonial.user_image && (
                      <SingleItem title="Featured Image">
                        <img
                          src={`${URI}${testimonial.user_image.url}`}
                          style={{
                            width: "200px",
                            height: "200px",
                            objectFit: "contain",
                          }}
                        />
                      </SingleItem>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ testimonial: state.testimonial });

const mapDispatchToProps = { getTestimonial };

export default connect(mapStateToProps, mapDispatchToProps)(ViewTestimonial);
