import api from "../utils/api";
import {
  GET_PROJECTS,
  TOTAL_PROJECTS,
  ADD_PROJECT,
  GET_PROJECT,
  RESET_PROJECT,
  PROJECTS_ERROR,
} from "./types";

import { setAlert } from "./alert";
// Get posts
export const countProject =
  ({ q, createdAt_lte, createdAt_gte }) =>
  async (dispatch) => {
    try {
      let query = "/projects/count?";
      if (q) {
        let newQ = ``;
        newQ += `&_q=${q}`;
        query = query + newQ;
      }
      if (createdAt_lte) {
        let newCreatedAt_gte = ``;
        newCreatedAt_gte += `&createdAt_lte=${createdAt_lte}`;
        query = query + newCreatedAt_gte;
      }
      if (createdAt_gte) {
        let newCreatedAt_gte = ``;
        newCreatedAt_gte += `&createdAt_gte=${createdAt_gte}`;
        query = query + newCreatedAt_gte;
      }

      let res = await api.get(query);
      dispatch({
        type: TOTAL_PROJECTS,
        payload: res.data,
      });
    } catch (err) {
      dispatch(setAlert("Something Went wrong", "danger"));
      dispatch({
        type: PROJECTS_ERROR,
        payload: err,
      });
      if (err.response && err.response.data) {
        const errors = err.response.data.message;
        if (errors && errors.length > 0) {
          const mainError = errors[0];
          if (mainError.messages) {
            mainError.messages.forEach((error) =>
              dispatch(setAlert(error.message, "danger"))
            );
          }
        }
      }
    }
  };

// Get posts
export const getProjects =
  ({ limit = 10, page = 1, sort = "DESC", q, createdAt_lte, createdAt_gte }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: RESET_PROJECT,
      });
      if (page) {
        const start = (page - 1) * limit;
        let query = `/projects?_limit=${limit}&_start=${start}&_sort=createdAt:${sort}`;
        if (q) {
          const newQ = `&_q=${q}`;
          query = query + newQ;
        }
        if (createdAt_lte) {
          const newLTE = `&createdAt_lte=${createdAt_lte}`;
          query = query + newLTE;
        }
        if (createdAt_gte) {
          const newGTE = `&createdAt_gte=${createdAt_gte}`;
          query = query + newGTE;
        }

        const res = await api.get(query);

        dispatch({
          type: GET_PROJECTS,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch(setAlert("Something Went wrong", "danger"));
      dispatch({
        type: PROJECTS_ERROR,
        payload: err,
      });
      if (err.response && err.response.data) {
        const errors = err.response.data.message;
        if (errors && errors.length > 0) {
          const mainError = errors[0];
          if (mainError.messages) {
            mainError.messages.forEach((error) =>
              dispatch(setAlert(error.message, "danger"))
            );
          }
        }
      }
    }
  };
// Get posts
export const getProject = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_PROJECT,
    });
    const res = await api.get("/projects/" + id);

    dispatch({
      type: GET_PROJECT,
      payload: res.data,
    });
  } catch (err) {
    dispatch(setAlert("Something Went wrong", "danger"));
    dispatch({
      type: PROJECTS_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};

export const addProject = (data, history) => async (dispatch) => {
  try {
    const res = await api.post(`/projects`, data);

    dispatch(setAlert("Project Saved!", "success"));
    dispatch({
      type: ADD_PROJECT,
      payload: res.data,
    });
  } catch (err) {
    dispatch(setAlert("Something Went Wrong", "danger"));
    dispatch({
      type: PROJECTS_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};
export const editProject = (id, data, history) => async (dispatch) => {
  try {
    const res = await api.put("/projects/" + id, data);

    dispatch(setAlert("Project Updated!", "success"));
  } catch (err) {
    dispatch(setAlert("Something Went wrong", "danger"));
    dispatch({
      type: PROJECTS_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};
export const deleteProject = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_PROJECT,
    });
    const res = await api.delete("/projects/" + id);

    dispatch(setAlert("Project Deleted!", "success"));
  } catch (err) {
    dispatch(setAlert("Something Went wrong", "danger"));
    dispatch({
      type: PROJECTS_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};
