export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";
// Project
export const GET_PROJECTS = "GET_PROJECTS";
export const TOTAL_PROJECTS = "TOTAL_PROJECTS";
export const ADD_PROJECT = "ADD_PROJECT";
export const EDIT_PROJECT = "EDIT_PROJECT";
export const GET_PROJECT = "GET_PROJECT";
export const RESET_PROJECT = "RESET_PROJECT";
export const PROJECTS_ERROR = "PROJECTS_ERROR";
// Blog
export const GET_BLOGS = "GET_BLOGS";
export const TOTAL_BLOGS = "TOTAL_BLOGS";
export const ADD_BLOG = "ADD_BLOG";
export const EDIT_BLOG = "EDIT_BLOG";
export const GET_BLOG = "GET_BLOG";
export const RESET_BLOG = "RESET_BLOG";
export const BLOGS_ERROR = "BLOGS_ERROR";
// Donations
export const GET_DONATIONS = "GET_DONATIONS";
export const TOTAL_DONATIONS = "TOTAL_DONATIONS";
export const ADD_DONATION = "ADD_DONATION";
export const EDIT_DONATION = "EDIT_DONATION";
export const GET_DONATION = "GET_DONATION";
export const RESET_DONATION = "RESET_DONATION";
export const DONATIONS_ERROR = "DONATIONS_ERROR";
//Contacts
export const GET_CONTACTS = "GET_CONTACTS";
export const TOTAL_CONTACTS = "TOTAL_CONTACTS";
export const ADD_CONTACT = "ADD_CONTACT";
export const EDIT_CONTACT = "EDIT_CONTACT";
export const GET_CONTACT = "GET_CONTACT";
export const RESET_CONTACT = "RESET_CONTACT";
export const CONTACTS_ERROR = "CONTACTS_ERROR";
// Upcoming Projects
export const GET_UPCOMING_PROJECTS = "GET_UPCOMING_PROJECTS";
export const TOTAL_UPCOMING_PROJECTS = "TOTAL_UPCOMING_PROJECTS";
export const ADD_UPCOMING_PROJECT = "ADD_UPCOMING_PROJECT";
export const EDIT_UPCOMING_PROJECT = "EDIT_UPCOMING_PROJECT";
export const GET_UPCOMING_PROJECT = "GET_UPCOMING_PROJECT";
export const RESET_UPCOMING_PROJECT = "RESET_UPCOMING_PROJECT";
export const UPCOMING_PROJECTS_ERROR = "UPCOMING_PROJECTS_ERROR";
// Causes
export const GET_CAUSES = "GET_CAUSES";
export const TOTAL_CAUSES = "TOTAL_CAUSES";
export const ADD_CAUSE = "ADD_CAUSE";
export const EDIT_CAUSE = "EDIT_CAUSE";
export const GET_CAUSE = "GET_CAUSE";
export const RESET_CAUSE = "RESET_CAUSE";
export const CAUSES_ERROR = "CAUSES_ERROR";
// EVENT
export const GET_EVENTS = "GET_EVENTS";
export const TOTAL_EVENTS = "TOTAL_EVENTS";
export const ADD_EVENT = "ADD_EVENT";
export const EDIT_EVENT = "EDIT_EVENT";
export const GET_EVENT = "GET_EVENT";
export const RESET_EVENT = "RESET_EVENT";
export const EVENTS_ERROR = "EVENTS_ERROR";
// Testimonial
export const GET_TESTIMONIALS = "GET_TESTIMONIALS";
export const TOTAL_TESTIMONIALS = "TOTAL_TESTIMONIALS";
export const ADD_TESTIMONIAL = "ADD_TESTIMONIAL";
export const EDIT_TESTIMONIAL = "EDIT_TESTIMONIAL";
export const GET_TESTIMONIAL = "GET_TESTIMONIAL";
export const RESET_TESTIMONIAL = "RESET_TESTIMONIAL";
export const TESTIMONIALS_ERROR = "TESTIMONIALS_ERROR";
// Newsletter
export const GET_NEWSLETTERS = "GET_NEWSLETTERS";
export const TOTAL_NEWSLETTERS = "TOTAL_NEWSLETTERS";
export const ADD_NEWSLETTER = "ADD_NEWSLETTER";
export const EDIT_NEWSLETTER = "EDIT_NEWSLETTER";
export const GET_NEWSLETTER = "GET_NEWSLETTER";
export const RESET_NEWSLETTER = "RESET_NEWSLETTER";
export const NEWSLETTERS_ERROR = "NEWSLETTERS_ERROR";
// Volunteer
export const GET_VOLUNTEERS = "GET_VOLUNTEERS";
export const TOTAL_VOLUNTEERS = "TOTAL_VOLUNTEERS";
export const ADD_VOLUNTEER = "ADD_VOLUNTEER";
export const EDIT_VOLUNTEER = "EDIT_VOLUNTEER";
export const GET_VOLUNTEER = "GET_VOLUNTEER";
export const RESET_VOLUNTEER = "RESET_VOLUNTEER";
export const VOLUNTEERS_ERROR = "VOLUNTEERS_ERROR";
// FAQ
export const GET_FAQS = "GET_FAQS";
export const TOTAL_FAQS = "TOTAL_FAQS";
export const ADD_FAQ = "ADD_FAQ";
export const EDIT_FAQ = "EDIT_FAQ";
export const GET_FAQ = "GET_FAQ";
export const RESET_FAQ = "RESET_FAQ";
export const FAQS_ERROR = "FAQS_ERROR";
// Pages
export const GET_PAGES = "GET_PAGES";
export const TOTAL_PAGES = "TOTAL_PAGES";
export const ADD_PAGE = "ADD_PAGE";
export const EDIT_PAGE = "EDIT_PAGE";
export const GET_PAGE = "GET_PAGE";
export const RESET_PAGE = "RESET_PAGE";
export const PAGES_ERROR = "PAGES_ERROR";
// Success Stories
export const GET_SUCCESS_STORIES = "GET_SUCCESS_STORIES";
export const TOTAL_SUCCESS_STORIES = "TOTAL_SUCCESS_STORIES";
export const ADD_SUCCESS_STORIE = "ADD_SUCCESS_STORIE";
export const EDIT_SUCCESS_STORIE = "EDIT_SUCCESS_STORIE";
export const GET_SUCCESS_STORIE = "GET_SUCCESS_STORIE";
export const RESET_SUCCESS_STORIE = "RESET_SUCCESS_STORIE";
export const SUCCESS_STORIES_ERROR = "SUCCESS_STORIES_ERROR";
// Students
export const GET_STUDENTS = "GET_STUDENTS";
export const TOTAL_STUDENTS = "TOTAL_STUDENTS";
export const ADD_STUDENT = "ADD_STUDENT";
export const EDIT_STUDENT = "EDIT_STUDENT";
export const GET_STUDENT = "GET_STUDENT";
export const RESET_STUDENT = "RESET_STUDENT";
export const STUDENTS_ERROR = "STUDENTS_ERROR";
