import React, { useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { getEvent } from "../../actions/events";
import Spinner from "../../layout/Spinner";
import SingleItem from "../../components/template/SingleItem";
import { Link } from "react-router-dom";
import { URI } from "../../constants/constants";
const ViewEvent = ({ getEvent, match, event: { loading, event } }) => {
  useEffect(() => {
    getEvent(match.params.id);
  }, [match]);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title="Events"
          mainLinkTitle="Events"
          mainLinkUrl="/events"
          activeLink="View"
        />
        {!loading ? (
          event && (
            <div className="container-fluid">
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        {event.name} <span>View</span>
                      </h4>
                      <p className="card-title-desc">
                        <Link to="/events" className="btn btn-soft-light">
                          <i className="fa fa-angle-left"></i> Events
                        </Link>
                        <Link
                          to={`/events/${event._id}/edit`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-edit"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="card-body">
                    <SingleItem title="Name" value={event.name} />
                    {event.gallery && event.gallery.length > 0 && (
                      <SingleItem title="Gallery">
                        <div className="d-flex" style={{ flexWrap: "wrap" }}>
                          {event.gallery.map((item) => {
                            return (
                              <img
                                src={`${URI}${item.url}`}
                                style={{
                                  width: "80px",
                                  height: "80px",
                                  objectFit: "contain",
                                }}
                              />
                            );
                          })}
                        </div>
                      </SingleItem>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ event: state.event });

const mapDispatchToProps = { getEvent };

export default connect(mapStateToProps, mapDispatchToProps)(ViewEvent);
