import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import AddBlog from "./Pages/blogs/AddBlog";
import EditBlog from "./Pages/blogs/EditBlog";
import SingleBlog from "./Pages/blogs/SingleBlog";
import ViewAllBlogs from "./Pages/blogs/ViewAllBlogs";
import AddCause from "./Pages/causes/AddCause";
import EditCause from "./Pages/causes/EditCause";
import SingleCause from "./Pages/causes/SingleCause";
import ViewAllCauses from "./Pages/causes/ViewAllCauses";
import AddContact from "./Pages/contacts/AddContact";
import EditContact from "./Pages/contacts/EditContact";
import SingleContact from "./Pages/contacts/SingleContact";
import ViewAllContacts from "./Pages/contacts/ViewAllContacts";
import Dashboard from "./Pages/Dashboard";
import AddDonation from "./Pages/donations/AddDonation";
import EditDonation from "./Pages/donations/EditDonation";
import SingleDonation from "./Pages/donations/SingleDonation";
import ViewAllDonations from "./Pages/donations/ViewAllDonations";
import AddEvent from "./Pages/events/AddEvent";
import EditEvent from "./Pages/events/EditEvent";
import SingleEvent from "./Pages/events/SingleEvent";
import ViewAllEvents from "./Pages/events/ViewAllEvents";
import AddNewsletter from "./Pages/newsletters/AddNewsletter";
import EditNewsletter from "./Pages/newsletters/EditNewsletter";
import SingleNewsletter from "./Pages/newsletters/SingleNewsletter";
import ViewAllNewsletters from "./Pages/newsletters/ViewAllNewsletters";
import AddProject from "./Pages/projects/AddProject";
import EditProject from "./Pages/projects/EditProject";
import SingleProject from "./Pages/projects/SingleProject";
import ViewAllProjects from "./Pages/projects/ViewAllProjects";
import AddTestimonial from "./Pages/testimonials/AddTestimonial";
import EditTestimonial from "./Pages/testimonials/EditTestimonial";
import SingleTestimonial from "./Pages/testimonials/SingleTestimonial";
import ViewAllTestimonials from "./Pages/testimonials/ViewAllTestimonials";
import AddUpcomingProject from "./Pages/upcoming-projects/AddUpcomingProject";
import EditUpcomingProject from "./Pages/upcoming-projects/EditUpcomingProject";
import SingleUpcomingProject from "./Pages/upcoming-projects/SingleUpcomingProject";
import ViewAllUpcomingProjects from "./Pages/upcoming-projects/ViewAllUpcomingProjects";
import AddVolunteer from "./Pages/volunteers/AddVolunteer";
import EditVolunteer from "./Pages/volunteers/EditVolunteer";
import SingleVolunteer from "./Pages/volunteers/SingleVolunteer";
import ViewAllVolunteer from "./Pages/volunteers/ViewAllVolunteer";
import AddFaq from "./Pages/faq/AddFaq";
import EditFaq from "./Pages/faq/EditFaq";
import SingleFaq from "./Pages/faq/SingleFaq";
import ViewAllFaq from "./Pages/faq/ViewAllFaq";
import AddPage from "./Pages/pages/AddPage";
import EditPage from "./Pages/pages/EditPage";
import SinglePage from "./Pages/pages/SinglePage";
import ViewAllPage from "./Pages/pages/ViewAllPage";
const Home = lazy(() => import("./Pages/Home"));
const ErrorPage = lazy(() => import("./Pages/ErrorPage"));

import BeforeLoginRoutes from "./Routes/BeforeLoginRoutes";
import PrivateRoutes from "./Routes/PrivateRoutes";
import ChangePassword from "./Pages/ChangePassword";

import ViewAllStudents from "./Pages/students/ViewAllStudents";
import SingleStudent from "./Pages/students/SingleStudent";
import EditStudent from "./Pages/students/EditStudent";
import AddStudent from "./Pages/students/AddStudent";

import ViewAllSuccessStories from "./Pages/success-stories/ViewAllSuccessStories";
import SingleSuccessStorie from "./Pages/success-stories/SingleSuccessStorie";
import EditSuccessStorie from "./Pages/success-stories/EditSuccessStorie";
import AddSuccessStorie from "./Pages/success-stories/AddSuccessStorie";

const renderLoader = () => (
  <img
    src="/images/spinner.gif"
    style={{ width: "200px", margin: "auto", display: "block" }}
    alt="Loading..."
  />
);
function Routes() {
  return (
    <Suspense fallback={renderLoader()}>
      <Switch>
        <BeforeLoginRoutes exact path="/" component={Home} />
        <PrivateRoutes exact path="/dashboard" component={Dashboard} />
        <PrivateRoutes exact path="/projects" component={ViewAllProjects} />
        <PrivateRoutes exact path="/projects/add" component={AddProject} />
        <PrivateRoutes
          exact
          path="/projects/:id/view"
          component={SingleProject}
        />
        <PrivateRoutes
          exact
          path="/projects/:id/edit"
          component={EditProject}
        />
        <PrivateRoutes
          exact
          path="/upcoming_projects"
          component={ViewAllUpcomingProjects}
        />
        <PrivateRoutes
          exact
          path="/upcoming_projects/add"
          component={AddUpcomingProject}
        />
        <PrivateRoutes
          exact
          path="/upcoming_projects/:id/view"
          component={SingleUpcomingProject}
        />
        <PrivateRoutes
          exact
          path="/upcoming_projects/:id/edit"
          component={EditUpcomingProject}
        />
        <PrivateRoutes exact path="/blogs" component={ViewAllBlogs} />
        <PrivateRoutes exact path="/blogs/add" component={AddBlog} />
        <PrivateRoutes exact path="/blogs/:id/view" component={SingleBlog} />
        <PrivateRoutes exact path="/blogs/:id/edit" component={EditBlog} />
        <PrivateRoutes exact path="/events" component={ViewAllEvents} />
        <PrivateRoutes exact path="/events/add" component={AddEvent} />
        <PrivateRoutes exact path="/events/:id/view" component={SingleEvent} />
        <PrivateRoutes exact path="/events/:id/edit" component={EditEvent} />
        <PrivateRoutes exact path="/donations" component={ViewAllDonations} />
        <PrivateRoutes exact path="/donations/add" component={AddDonation} />
        <PrivateRoutes
          exact
          path="/donations/:id/view"
          component={SingleDonation}
        />
        <PrivateRoutes
          exact
          path="/donations/:id/edit"
          component={EditDonation}
        />
        <PrivateRoutes
          exact
          path="/testimonials"
          component={ViewAllTestimonials}
        />
        <PrivateRoutes
          exact
          path="/testimonials/add"
          component={AddTestimonial}
        />
        <PrivateRoutes
          exact
          path="/testimonials/:id/view"
          component={SingleTestimonial}
        />
        <PrivateRoutes
          exact
          path="/testimonials/:id/edit"
          component={EditTestimonial}
        />

        <PrivateRoutes exact path="/contacts" component={ViewAllContacts} />
        <PrivateRoutes exact path="/contacts/add" component={AddContact} />
        <PrivateRoutes
          exact
          path="/contacts/:id/view"
          component={SingleContact}
        />
        <PrivateRoutes
          exact
          path="/contacts/:id/edit"
          component={EditContact}
        />
        <PrivateRoutes exact path="/causes" component={ViewAllCauses} />
        <PrivateRoutes exact path="/causes/add" component={AddCause} />
        <PrivateRoutes exact path="/causes/:id/view" component={SingleCause} />
        <PrivateRoutes exact path="/causes/:id/edit" component={EditCause} />
        <PrivateRoutes
          exact
          path="/newsletters"
          component={ViewAllNewsletters}
        />
        <PrivateRoutes
          exact
          path="/newsletters/add"
          component={AddNewsletter}
        />
        <PrivateRoutes
          exact
          path="/newsletters/:id/view"
          component={SingleNewsletter}
        />
        <PrivateRoutes
          exact
          path="/newsletters/:id/edit"
          component={EditNewsletter}
        />
        <PrivateRoutes exact path="/volunteers/add" component={AddVolunteer} />
        <PrivateRoutes exact path="/volunteers" component={ViewAllVolunteer} />
        <PrivateRoutes
          exact
          path="/volunteers/:id/view"
          component={SingleVolunteer}
        />
        <PrivateRoutes
          exact
          path="/volunteers/:id/edit"
          component={EditVolunteer}
        />
        <PrivateRoutes
          exact
          path="/students"
          component={ViewAllStudents}
        />
        <PrivateRoutes
          exact
          path="/students/:id/view"
          component={SingleStudent}
        />
        <PrivateRoutes
          exact
          path="/students/:id/edit"
          component={EditStudent}
        />
        <PrivateRoutes
          exact
          path="/students/add"
          component={AddStudent}
        />
        <PrivateRoutes
          exact
          path="/success-stories"
          component={ViewAllSuccessStories}
        />
        <PrivateRoutes
          exact
          path="/success-stories/:id/view"
          component={SingleSuccessStorie}
        />
        <PrivateRoutes
          exact
          path="/success-stories/:id/edit"
          component={EditSuccessStorie}
        />
        <PrivateRoutes
          exact
          path="/success-stories/add"
          component={AddSuccessStorie}
        />
        <PrivateRoutes exact path="/faqs/add" component={AddFaq} />
        <PrivateRoutes exact path="/faqs" component={ViewAllFaq} />
        <PrivateRoutes exact path="/faqs/:id/view" component={SingleFaq} />
        <PrivateRoutes exact path="/faqs/:id/edit" component={EditFaq} />
        <PrivateRoutes exact path="/pages/add" component={AddPage} />
        <PrivateRoutes exact path="/pages" component={ViewAllPage} />
        <PrivateRoutes exact path="/pages/:id/view" component={SinglePage} />
        <PrivateRoutes exact path="/pages/:id/edit" component={EditPage} />
        <PrivateRoutes exact path="/profile" component={ChangePassword} />
        <Route component={ErrorPage} />
      </Switch>
    </Suspense>
  );
}

export default Routes;
