import api from "../utils/api";
import {
  GET_PAGES,
  TOTAL_PAGES,
  ADD_PAGE,
  GET_PAGE,
  RESET_PAGE,
  PAGES_ERROR,
} from "./types";

import { setAlert } from "./alert";
// Get posts
export const countPage =
  ({ q, createdAt_lte, createdAt_gte }) =>
  async (dispatch) => {
    try {
      let query = "/pages/count?";
      if (q) {
        let newQ = ``;
        newQ += `&_q=${q}`;
        query = query + newQ;
      }
      if (createdAt_lte) {
        let newCreatedAt_gte = ``;
        newCreatedAt_gte += `&createdAt_lte=${createdAt_lte}`;
        query = query + newCreatedAt_gte;
      }
      if (createdAt_gte) {
        let newCreatedAt_gte = ``;
        newCreatedAt_gte += `&createdAt_gte=${createdAt_gte}`;
        query = query + newCreatedAt_gte;
      }

      let res = await api.get(query);
      dispatch({
        type: TOTAL_PAGES,
        payload: res.data,
      });
    } catch (err) {
      dispatch(setAlert("Something Went wrong", "danger"));
      dispatch({
        type: PAGES_ERROR,
        payload: err,
      });
      if (err.response && err.response.data) {
        const errors = err.response.data.message;
        if (errors && errors.length > 0) {
          const mainError = errors[0];
          if (mainError.messages) {
            mainError.messages.forEach((error) =>
              dispatch(setAlert(error.message, "danger"))
            );
          }
        }
      }
    }
  };

// Get posts
export const getPages =
  ({ limit = 10, page = 1, sort = "DESC", q, createdAt_lte, createdAt_gte }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: RESET_PAGE,
      });
      if (page) {
        const start = (page - 1) * limit;
        let query = `/pages?_limit=${limit}&_start=${start}&_sort=createdAt:${sort}`;
        if (q) {
          const newQ = `&_q=${q}`;
          query = query + newQ;
        }
        if (createdAt_lte) {
          const newLTE = `&createdAt_lte=${createdAt_lte}`;
          query = query + newLTE;
        }
        if (createdAt_gte) {
          const newGTE = `&createdAt_gte=${createdAt_gte}`;
          query = query + newGTE;
        }

        const res = await api.get(query);

        dispatch({
          type: GET_PAGES,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch(setAlert("Something Went wrong", "danger"));
      dispatch({
        type: PAGES_ERROR,
        payload: err,
      });
      if (err.response && err.response.data) {
        const errors = err.response.data.message;
        if (errors && errors.length > 0) {
          const mainError = errors[0];
          if (mainError.messages) {
            mainError.messages.forEach((error) =>
              dispatch(setAlert(error.message, "danger"))
            );
          }
        }
      }
    }
  };
// Get posts
export const getPage = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_PAGE,
    });
    const res = await api.get("/pages/" + id);

    dispatch({
      type: GET_PAGE,
      payload: res.data,
    });
  } catch (err) {
    dispatch(setAlert("Something Went wrong", "danger"));
    dispatch({
      type: PAGES_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};

export const addPage = (data, history) => async (dispatch) => {
  try {
    const res = await api.post(`/pages`, data);

    dispatch(setAlert("Page Saved!", "success"));
    dispatch({
      type: ADD_PAGE,
      payload: res.data,
    });
  } catch (err) {
    dispatch(setAlert("Something Went wrong", "danger"));
    dispatch({
      type: PAGES_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};
export const editPage = (id, data, history) => async (dispatch) => {
  try {
    const res = await api.put("/pages/" + id, data);

    dispatch(setAlert("Page Updated!", "success"));
  } catch (err) {
    dispatch(setAlert("Something Went wrong", "danger"));
    dispatch({
      type: PAGES_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};

export const deletePage = (id) => async (dispatch) => {
  try {
    const res = await api.delete("/pages/" + id);

    dispatch(setAlert("Page Deleted!", "success"));
  } catch (err) {
    dispatch(setAlert("Something Went wrong", "danger"));
    dispatch({
      type: PAGES_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};
