import React, { Component } from "react";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { URI } from "../constants/constants";

export class EditorWYSIWYG extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: "",
    };
  }
  componentDidMount() {
    this.setState({
      editorState: EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(this.props.value))
      ),
    });
  }

  uploadImageCallBack = async (file) => {
    console.log(file);
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", `${URI}/upload`);

      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );
      const data = new FormData();
      data.append("files", file, file.name);
      xhr.send(data);
      xhr.addEventListener("load", () => {
        const response = JSON.parse(xhr.responseText);
        console.log(response);
        // resolve(`${URI}${response[0].url}`);
        resolve({ data: { link: `${URI}${response[0].url}` } });
      });
      xhr.addEventListener("error", () => {
        const error = JSON.parse(xhr.responseText);
        console.log(error);
        reject(error);
      });
    });
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
    this.props.changeValue(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };

  render() {
    const { editorState } = this.state;
    return (
      <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={this.onEditorStateChange}
        toolbar={{
          image: {
            uploadCallback: this.uploadImageCallBack,
          },
        }}
      />
    );
  }
}

export default EditorWYSIWYG;
