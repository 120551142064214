import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Form, Formik } from "formik";
import { getSuccessStorie, editSuccessStorie } from "../../actions/success_stories";
import * as Yup from "yup";
import {
    CheckBox,
    SelectBox,
    TextArea,
    TextInput,
} from "../../components/Form/Form";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Spinner from "../../layout/Spinner";
import slugify from "react-slugify";
import EditorWYSIWYG from "../../components/EditorWYSIWYG";
import { URI } from "../../constants/constants";
const EditSuccessStorie = ({
    editSuccessStorie,
    getSuccessStorie,
    alert,
    match,
    success_storie: { loading, success_storie },
}) => {
    let history = useHistory();

    const [featuredImage, setFeaturedImage] = useState(null);
    const [gallery, setGallery] = useState(null);

    useEffect(() => {
        getSuccessStorie(match.params.id);
    }, [match]);
    return (
        <div className="pace-done">
            <div>
                <Header />
                <BreadCrumb
                    title="Edit SuccessStorie"
                    mainLinkTitle="SuccessStories"
                    mainLinkUrl="/success_stories"
                    activeLink="Edit"
                />
            </div>
            <div className="container-fluid">
                <div className="col-lg-12">
                    <div className="card">
                        {!loading ? (
                            success_storie && (
                                <div>
                                    <div className="card-header">
                                        <div>
                                            <h4 className="card-title">
                                                {success_storie.name} - <span>Edit</span>{" "}
                                            </h4>
                                            <p className="card-title-desc">
                                                <Link to="/success-stories" className="btn btn-soft-light">
                                                    <i className="fa fa-angle-left"></i> SuccessStories
                                                </Link>
                                                <Link
                                                    to={`/success-stories/${success_storie._id}/view`}
                                                    className="btn btn-soft-light"
                                                >
                                                    <i className="fa fa-eye"></i>
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <Formik
                                                initialValues={{
                                                    name: success_storie.name,
                                                    description: success_storie.description,
                                                    money_spent: "",
                                                    slug: success_storie.slug,

                                                }}
                                                validationSchema={Yup.object({
                                                    name: Yup.string().required("Required"),
                                                    description: Yup.string().required("Required"),
                                                    slug: Yup.string().required("Required"),

                                                })}
                                                onSubmit={async (
                                                    values,
                                                    { setSubmitting, resetForm }
                                                ) => {
                                                    setSubmitting(true);
                                                    const newForm = new FormData();
                                                    if (featuredImage) {
                                                        newForm.append(
                                                            `files.images`,
                                                            featuredImage,
                                                            featuredImage.name
                                                        );
                                                    }
                                                    if (gallery) {
                                                        Array.from(gallery).forEach((image, index) => {
                                                            console.log("IMAGE", image);
                                                            newForm.append(
                                                                `files.gallery`,
                                                                image,
                                                                image.name
                                                            );
                                                        });
                                                    }
                                                    newForm.append("data", JSON.stringify(values));
                                                    await editSuccessStorie(success_storie._id, newForm);
                                                    resetForm();
                                                    setSubmitting(false);
                                                    history.push(`/success-stories/${success_storie._id}/view`);
                                                }}
                                            >
                                                {(formik) => {
                                                    console.log(formik);
                                                    return (
                                                        <Form autoComplete={"off"}>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <TextInput
                                                                        label="Name"
                                                                        name="name"
                                                                        type="text"
                                                                        placeholder="Enter Name"
                                                                        onChange={(e) => {
                                                                            formik.setFieldValue("name", e.target.value);
                                                                            formik.setFieldValue(
                                                                                "slug",
                                                                                slugify(e.target.value)
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                                {
                                                                    // <div className="col-md-6">
                                                                    //     <TextInput
                                                                    //         label="Money Spent"
                                                                    //         name="money_spent"
                                                                    //         type="text"
                                                                    //         placeholder="Enter Money Spent"
                                                                    //     />
                                                                    // </div>
                                                                }
                                                                <div className="col-md-6">
                                                                    <label> Featured Image </label>
                                                                    <br />
                                                                    <input
                                                                        type="file"
                                                                        className="form-control-file"
                                                                        onChange={(e) =>
                                                                            setFeaturedImage(e.target.files[0])
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label> Documents </label>
                                                                    <br />
                                                                    <input
                                                                        type="file"
                                                                        multiple
                                                                        className="form-control-file"
                                                                        onChange={(e) => setGallery(e.target.files)}
                                                                    />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label>Slug</label>
                                                                    <input
                                                                        label="Slug"
                                                                        name="slug"
                                                                        type="text"
                                                                        placeholder="Enter Slug"
                                                                        className="form-control"
                                                                        value={formik.values.slug}
                                                                        onChange={(e) =>
                                                                            formik.setFieldValue(
                                                                                "slug",
                                                                                slugify(e.target.value)
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <label> Description </label>
                                                                    <EditorWYSIWYG
                                                                        value={formik.values.description}
                                                                        changeValue={(value) => {
                                                                            formik.setFieldValue(`description`, value);
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="col-md-12 text-center m-3">
                                                                    <button
                                                                        type="submit"
                                                                        className="btn btn-success"
                                                                    >
                                                                        {formik.isSubmitting
                                                                            ? "Processing..."
                                                                            : "Save"}
                                                                    </button>
                                                                    <a
                                                                        className="btn btn-secondary m-3"
                                                                        onClick={() => history.goBack()}
                                                                    >
                                                                        <i className="fa fa-angle-left"></i> Go Back
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    );
                                                }}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            )
                        ) : (
                            <div className="text-center">
                                <Spinner />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    alert: state.alert,
    success_storie: state.success_storie,
});

const mapDispatchToProps = { editSuccessStorie, getSuccessStorie };

export default connect(mapStateToProps, mapDispatchToProps)(EditSuccessStorie);
