import React, { useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Form, Formik } from "formik";
import { getPage, editPage } from "../../actions/pages";
import EditorWYSIWYG from "../../components/EditorWYSIWYG";
import * as Yup from "yup";
import {
  CheckBox,
  SelectBox,
  TextArea,
  TextInput,
} from "../../components/Form/Form";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Spinner from "../../layout/Spinner";
import slugify from "react-slugify";
const EditPage = ({
  editPage,
  getPage,
  alert,
  match,
  page: { loading, page },
}) => {
  let history = useHistory();
  useEffect(() => {
    getPage(match.params.id);
  }, [match]);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title="Edit Page"
          mainLinkTitle="Pages"
          mainLinkUrl="/pages"
          activeLink="Edit"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            {!loading ? (
              page && (
                <div>
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        {page.title} - <span>Edit</span>{" "}
                      </h4>
                      <p className="card-title-desc">
                        <Link to="/pages" className="btn btn-soft-light">
                          <i className="fa fa-angle-left"></i> Pages
                        </Link>
                        <Link
                          to={`/pages/${page._id}/view`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <Formik
                        initialValues={{
                          title: page.title,
                          content: page.content,
                          slug: page.slug,
                        }}
                        validationSchema={Yup.object({
                          title: Yup.string().required("Required"),
                          content: Yup.string().required("Required"),
                        })}
                        onSubmit={async (
                          values,
                          { setSubmitting, resetForm }
                        ) => {
                          setSubmitting(true);
                          await editPage(page._id, values);
                          resetForm();
                          setSubmitting(false);
                          history.push(`/pages/${page._id}/view`);
                        }}
                      >
                        {(formik) => {
                          console.log(formik);
                          return (
                            <Form autoComplete={"off"}>
                              <div className="row">
                                <div className="col-md-6">
                                  <TextInput
                                    label="Title"
                                    name="title"
                                    type="string"
                                    placeholder="Enter Title"
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        "title",
                                        e.target.value
                                      );
                                      formik.setFieldValue(
                                        "slug",
                                        slugify(e.target.value)
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-md-12">
                                  <label> Content </label>
                                  <EditorWYSIWYG
                                    value={formik.values.content}
                                    changeValue={(value) => {
                                      formik.setFieldValue(`content`, value);
                                    }}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <label>Slug</label>
                                  <input
                                    label="Slug"
                                    name="slug"
                                    type="text"
                                    placeholder="Enter Slug"
                                    className="form-control"
                                    value={formik.values.slug}
                                    onChange={(e) =>
                                      formik.setFieldValue(
                                        "slug",
                                        slugify(e.target.value)
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-md-12 text-center m-3">
                                  <button
                                    type="submit"
                                    className="btn btn-success"
                                  >
                                    {formik.isSubmitting
                                      ? "Processing..."
                                      : "Save"}
                                  </button>
                                  <a
                                    className="btn btn-secondary m-3"
                                    onClick={() => history.goBack()}
                                  >
                                    <i className="fa fa-angle-left"></i> Go Back
                                  </a>
                                </div>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              )
            ) : (
              <div className="text-center">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
  page: state.page,
});

const mapDispatchToProps = { editPage, getPage };

export default connect(mapStateToProps, mapDispatchToProps)(EditPage);
