import React, { useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { getContact } from "../../actions/contacts";
import Spinner from "../../layout/Spinner";
import SingleItem from "../../components/template/SingleItem";
import { Link } from "react-router-dom";
const ViewContact = ({ getContact, match, contact: { loading, contact } }) => {
  useEffect(() => {
    getContact(match.params.id);
  }, [match]);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title="Contacts"
          mainLinkTitle="Contacts"
          mainLinkUrl="/contacts"
          activeLink="View"
        />
        {!loading ? (
          contact && (
            <div className="container-fluid">
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        {contact.name} <span>View</span>
                      </h4>
                      <p className="card-title-desc">
                        <Link to="/contacts" className="btn btn-soft-light">
                          <i className="fa fa-angle-left"></i> Contacts
                        </Link>
                        <Link
                          to={`/contacts/${contact._id}/edit`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-edit"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="card-body">
                    <SingleItem title="name" value={contact.name} />
                    <SingleItem title="email" value={contact.email} />
                    <SingleItem title="phone" value={contact.phone} />
                    <SingleItem title="subject" value={contact.subject} />
                    <SingleItem title="message" value={contact.message} />
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ contact: state.contact });

const mapDispatchToProps = { getContact };

export default connect(mapStateToProps, mapDispatchToProps)(ViewContact);
