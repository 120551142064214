import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Form, Formik } from "formik";

import * as Yup from "yup";
import {
    CheckBox,
    SelectBox,
    TextArea,
    TextInput,
} from "../../components/Form/Form";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { addStudent } from "../../actions/students";
import EditorWYSIWYG from "../../components/EditorWYSIWYG";
import slugify from "react-slugify";

const AddStudent = ({ addStudent, alert }) => {

    let history = useHistory();
    const [featuredImage, setFeaturedImage] = useState(null);

    return (
        <div className="pace-done">
            <div>
                <Header />
                <BreadCrumb
                    title="Add student"
                    mainLinkTitle="Students"
                    mainLinkUrl="/students"
                    activeLink="Add"
                />
            </div>
            <div className="container-fluid">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Student</h4>
                            <p className="card-title-desc">Enter Details to add Student</p>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <Formik
                                    initialValues={{
                                        name: "",
                                        age: "",
                                        description: "",
                                        slug: "",
                                    }}
                                    validationSchema={Yup.object({
                                        name: Yup.string().required("Required"),
                                        age: Yup.string().required("Required"),
                                        description: Yup.string().required("Required"),
                                        slug: Yup.string().required("Required"),
                                    })}
                                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                                        setSubmitting(true);
                                        const newForm = new FormData();
                                        if (featuredImage) {
                                            newForm.append(
                                                `files.featured_img`,
                                                featuredImage,
                                                featuredImage.name
                                            );
                                        }

                                        newForm.append("data", JSON.stringify(values));
                                        await addStudent(newForm);
                                        resetForm();
                                        setSubmitting(false);
                                        history.push("/students");
                                    }}
                                >
                                    {(formik) => {
                                        return (
                                            <Form autoComplete={"off"}>
                                                <div className="row">

                                                    <div className="col-md-6">
                                                        <TextInput
                                                            label="Name"
                                                            name="name"
                                                            type="text"
                                                            placeholder="Enter Name"
                                                            onChange={(e) => {
                                                                formik.setFieldValue("name", e.target.value);
                                                                formik.setFieldValue(
                                                                    "slug",
                                                                    slugify(e.target.value)
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <TextInput
                                                            label="Age"
                                                            name="age"
                                                            type="string"
                                                            placeholder="Enter Age"
                                                        />
                                                    </div>

                                                    <div className="col-md-6">
                                                        <label> Featured Image </label>
                                                        <br />
                                                        <input
                                                            type="file"
                                                            className="form-control-file"
                                                            onChange={(e) =>
                                                                setFeaturedImage(e.target.files[0])
                                                            }
                                                        />
                                                    </div>

                                                    <div className="col-md-6">
                                                        <label>Slug</label>
                                                        <input
                                                            label="Slug"
                                                            name="slug"
                                                            type="text"
                                                            placeholder="Enter Slug"
                                                            className="form-control"
                                                            value={formik.values.slug}
                                                            onChange={(e) =>
                                                                formik.setFieldValue(
                                                                    "slug",
                                                                    slugify(e.target.value)
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-md-12">
                                                        <label> Description </label>
                                                        <EditorWYSIWYG
                                                            value={formik.values.description}
                                                            changeValue={(value) => {
                                                                formik.setFieldValue(`description`, value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12 text-center m-3">
                                                        <button type="submit" className="btn btn-success">
                                                            {formik.isSubmitting ? "Processing..." : "Save"}
                                                        </button>
                                                        <button
                                                            className="btn btn-secondary m-3"
                                                            onClick={history.goBack}
                                                        >
                                                            <i className="fa fa-angle-left"></i> Go Back
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({ alert: state.alert });

const mapDispatchToProps = { addStudent };

export default connect(mapStateToProps, mapDispatchToProps)(AddStudent);
