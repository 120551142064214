import React, { useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { getNewsletter } from "../../actions/newsletters";
import Spinner from "../../layout/Spinner";
import SingleItem from "../../components/template/SingleItem";
import { Link } from "react-router-dom";
const ViewNewsletter = ({
  getNewsletter,
  match,
  newsletter: { loading, newsletter },
}) => {
  useEffect(() => {
    getNewsletter(match.params.id);
  }, [match]);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title="Newsletters"
          mainLinkTitle="Newsletters"
          mainLinkUrl="/newsletters"
          activeLink="View"
        />
        {!loading ? (
          newsletter && (
            <div className="container-fluid">
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        {newsletter.email} <span>View</span>
                      </h4>
                      <p className="card-title-desc">
                        <Link to="/newsletters" className="btn btn-soft-light">
                          <i className="fa fa-angle-left"></i> Newsletters
                        </Link>
                        <Link
                          to={`/newsletters/${newsletter._id}/edit`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-edit"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="card-body">
                    <SingleItem title="Email" value={newsletter.email} />
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ newsletter: state.newsletter });

const mapDispatchToProps = { getNewsletter };

export default connect(mapStateToProps, mapDispatchToProps)(ViewNewsletter);
