import React, { useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { getPage } from "../../actions/pages";
import Spinner from "../../layout/Spinner";
import SingleItem from "../../components/template/SingleItem";
import { Link } from "react-router-dom";
const ViewPage = ({ getPage, match, page: { loading, page } }) => {
  useEffect(() => {
    getPage(match.params.id);
  }, [match]);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title="Pages"
          mainLinkTitle="Pages"
          mainLinkUrl="/pages"
          activeLink="View"
        />
        {!loading ? (
          page && (
            <div className="container-fluid">
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        {page.title} <span>View</span>
                      </h4>
                      <p className="card-title-desc">
                        <Link to="/pages" className="btn btn-soft-light">
                          <i className="fa fa-angle-left"></i> Pages
                        </Link>
                        <Link
                          to={`/pages/${page._id}/edit`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-edit"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="card-body">
                    <SingleItem title="Title" value={page.title} />
                    <SingleItem title="Content" value={page.content} />
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ page: state.page });

const mapDispatchToProps = { getPage };

export default connect(mapStateToProps, mapDispatchToProps)(ViewPage);
