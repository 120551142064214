import React, { useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Form, Formik } from "formik";
import { getVolunteer, editVolunteer } from "../../actions/volunteers";
import * as Yup from "yup";
import {
  CheckBox,
  SelectBox,
  TextArea,
  TextInput,
} from "../../components/Form/Form";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Spinner from "../../layout/Spinner";

const EditVolunteer = ({
  editVolunteer,
  getVolunteer,
  alert,
  match,
  volunteer: { loading, volunteer },
}) => {
  let history = useHistory();
  useEffect(() => {
    getVolunteer(match.params.id);
  }, [match]);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title="Edit Volunteer"
          mainLinkTitle="Volunteers"
          mainLinkUrl="/volunteers"
          activeLink="Edit"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            {!loading ? (
              volunteer && (
                <div>
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        {volunteer.name} - <span>Edit</span>{" "}
                      </h4>
                      <p className="card-title-desc">
                        <Link to="/volunteers" className="btn btn-soft-light">
                          <i className="fa fa-angle-left"></i> Volunteers
                        </Link>
                        <Link
                          to={`/volunteers/${volunteer._id}/view`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <Formik
                        initialValues={{
                          name: volunteer.name,
                          email: volunteer.email,
                          phone: volunteer.phone,
                          address: volunteer.address,
                          city: volunteer.city,
                          state: volunteer.state,
                          pin: volunteer.pin,
                        }}
                        validationSchema={Yup.object({
                          name: Yup.string().required("Required"),
                          email: Yup.string().required("Required"),
                          phone: Yup.string().required("Required"),
                          address: Yup.string().required("Required"),
                          city: Yup.string().required("Required"),
                          state: Yup.string().required("Required"),
                          pin: Yup.string().required("Required"),
                        })}
                        onSubmit={async (
                          values,
                          { setSubmitting, resetForm }
                        ) => {
                          setSubmitting(true);
                          await editVolunteer(volunteer._id, values);
                          resetForm();
                          setSubmitting(false);
                          history.push(`/volunteers/${volunteer._id}/view`);
                        }}
                      >
                        {(formik) => {
                          console.log(formik);
                          return (
                            <Form autoComplete={"off"}>
                              <div className="row">
                                <div className="col-md-6">
                                  <TextInput
                                    label="Name"
                                    name="name"
                                    type="text"
                                    placeholder="Enter Name"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="Email"
                                    name="email"
                                    type="email"
                                    placeholder="Enter Email"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="Phone"
                                    name="phone"
                                    type="text"
                                    placeholder="Enter Phone"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="Address"
                                    name="address"
                                    type="string"
                                    placeholder="Enter Address"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="City"
                                    name="city"
                                    type="string"
                                    placeholder="Enter City"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="State"
                                    name="state"
                                    type="string"
                                    placeholder="Enter State"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="Pin"
                                    name="pin"
                                    type="string"
                                    placeholder="Enter Pin"
                                  />
                                </div>
                                <div className="col-md-12 text-center m-3">
                                  <button
                                    type="submit"
                                    className="btn btn-success"
                                  >
                                    {formik.isSubmitting
                                      ? "Processing..."
                                      : "Save"}
                                  </button>
                                  <a
                                    className="btn btn-secondary m-3"
                                    onClick={() => history.goBack()}
                                  >
                                    <i className="fa fa-angle-left"></i> Go Back
                                  </a>
                                </div>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              )
            ) : (
              <div className="text-center">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
  volunteer: state.volunteer,
});

const mapDispatchToProps = { editVolunteer, getVolunteer };

export default connect(mapStateToProps, mapDispatchToProps)(EditVolunteer);
