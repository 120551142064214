import api from "../utils/api";
import {
  GET_FAQS,
  TOTAL_FAQS,
  ADD_FAQ,
  GET_FAQ,
  RESET_FAQ,
  FAQS_ERROR,
} from "./types";

import { setAlert } from "./alert";
// Get posts
export const countFaq =
  ({ q, createdAt_lte, createdAt_gte }) =>
  async (dispatch) => {
    try {
      let query = "/faqs/count?";
      if (q) {
        let newQ = ``;
        newQ += `&_q=${q}`;
        query = query + newQ;
      }
      if (createdAt_lte) {
        let newCreatedAt_gte = ``;
        newCreatedAt_gte += `&createdAt_lte=${createdAt_lte}`;
        query = query + newCreatedAt_gte;
      }
      if (createdAt_gte) {
        let newCreatedAt_gte = ``;
        newCreatedAt_gte += `&createdAt_gte=${createdAt_gte}`;
        query = query + newCreatedAt_gte;
      }

      let res = await api.get(query);
      dispatch({
        type: TOTAL_FAQS,
        payload: res.data,
      });
    } catch (err) {
      dispatch(setAlert("Something Went wrong", "danger"));
      dispatch({
        type: FAQS_ERROR,
        payload: err,
      });
      if (err.response && err.response.data) {
        const errors = err.response.data.message;
        if (errors && errors.length > 0) {
          const mainError = errors[0];
          if (mainError.messages) {
            mainError.messages.forEach((error) =>
              dispatch(setAlert(error.message, "danger"))
            );
          }
        }
      }
    }
  };

// Get posts
export const getFaqs =
  ({ limit = 10, page = 1, sort = "DESC", q, createdAt_lte, createdAt_gte }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: RESET_FAQ,
      });
      if (page) {
        const start = (page - 1) * limit;
        let query = `/faqs?_limit=${limit}&_start=${start}&_sort=createdAt:${sort}`;
        if (q) {
          const newQ = `&_q=${q}`;
          query = query + newQ;
        }
        if (createdAt_lte) {
          const newLTE = `&createdAt_lte=${createdAt_lte}`;
          query = query + newLTE;
        }
        if (createdAt_gte) {
          const newGTE = `&createdAt_gte=${createdAt_gte}`;
          query = query + newGTE;
        }

        const res = await api.get(query);

        dispatch({
          type: GET_FAQS,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch(setAlert("Something Went wrong", "danger"));
      dispatch({
        type: FAQS_ERROR,
        payload: err,
      });
      if (err.response && err.response.data) {
        const errors = err.response.data.message;
        if (errors && errors.length > 0) {
          const mainError = errors[0];
          if (mainError.messages) {
            mainError.messages.forEach((error) =>
              dispatch(setAlert(error.message, "danger"))
            );
          }
        }
      }
    }
  };
// Get posts
export const getFaq = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_FAQ,
    });
    const res = await api.get("/faqs/" + id);

    dispatch({
      type: GET_FAQ,
      payload: res.data,
    });
  } catch (err) {
    dispatch(setAlert("Something Went wrong", "danger"));
    dispatch({
      type: FAQS_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};

export const addFaq = (data, history) => async (dispatch) => {
  try {
    const res = await api.post(`/faqs`, data);

    dispatch(setAlert("Faq Saved!", "success"));
    dispatch({
      type: ADD_FAQ,
      payload: res.data,
    });
  } catch (err) {
    dispatch(setAlert("Something Went wrong", "danger"));
    dispatch({
      type: FAQS_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};
export const editFaq = (id, data, history) => async (dispatch) => {
  try {
    const res = await api.put("/faqs/" + id, data);

    dispatch(setAlert("Faq Updated!", "success"));
  } catch (err) {
    dispatch(setAlert("Something Went wrong", "danger"));
    dispatch({
      type: FAQS_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};

export const deleteFaq = (id) => async (dispatch) => {
  try {
    const res = await api.delete("/faqs/" + id);

    dispatch(setAlert("Faq Deleted!", "success"));
  } catch (err) {
    dispatch(setAlert("Something Went wrong", "danger"));
    dispatch({
      type: FAQS_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};
