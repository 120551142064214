import { combineReducers } from "redux";
import auth from "./auth";
import alert from "./alert";
import project from "./project";
import blog from "./blog";
import donation from "./donation";
import contact from "./contact";
import upcoming_project from "./upcoming_project";
import cause from "./cause";
import event from "./event";
import testimonial from "./testimonial";
import newsletter from "./newsletter";
import volunteer from "./volunteer";
import faq from "./faq";
import page from "./page";
import success_storie from "./success_storie";
import student from "./student";
export default combineReducers({
  auth,
  alert,
  project,
  blog,
  donation,
  contact,
  upcoming_project,
  cause,
  event,
  testimonial,
  newsletter,
  volunteer,
  faq,
  page,
  success_storie,
  student,
});
