import React, { useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { getBlog } from "../../actions/blogs";
import Spinner from "../../layout/Spinner";
import SingleItem from "../../components/template/SingleItem";
import { Link } from "react-router-dom";
import { URI } from "../../constants/constants";
const ViewBlog = ({ getBlog, match, blog: { loading, blog } }) => {
  useEffect(() => {
    getBlog(match.params.id);
  }, [match]);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title="Blogs"
          mainLinkTitle="Blogs"
          mainLinkUrl="/blogs"
          activeLink="View"
        />
        {!loading ? (
          blog && (
            <div className="container-fluid">
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        {blog.name} <span>View</span>
                      </h4>
                      <p className="card-title-desc">
                        <Link to="/blogs" className="btn btn-soft-light">
                          <i className="fa fa-angle-left"></i> Blogs
                        </Link>
                        <Link
                          to={`/blogs/${blog._id}/edit`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-edit"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="card-body">
                    <SingleItem title="title" value={blog.title} />
                    <SingleItem title="content" value={blog.content} />
                    <SingleItem title="meta title" value={blog.meta_title} />
                    <SingleItem
                      title="meta description"
                      value={blog.meta_description}
                    />
                    {blog.featured_image && (
                      <SingleItem title="Featured Image">
                        <img
                          src={`${URI}${blog.featured_image.url}`}
                          style={{
                            width: "200px",
                            height: "200px",
                            objectFit: "contain",
                          }}
                        />
                      </SingleItem>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ blog: state.blog });

const mapDispatchToProps = { getBlog };

export default connect(mapStateToProps, mapDispatchToProps)(ViewBlog);
