import React, { useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { getStudent } from "../../actions/students";
import Spinner from "../../layout/Spinner";
import SingleItem from "../../components/template/SingleItem";
import { Link } from "react-router-dom";
import { URI } from "../../constants/constants";


const ViewStudent = ({ getStudent, match, student: { loading, student } }) => {
    useEffect(() => {
        getStudent(match.params.id);
    }, [match]);
    return (
        <div className="pace-done">
            <div>
                <Header />
                <BreadCrumb
                    title="Students"
                    mainLinkTitle="Students"
                    mainLinkUrl="/students"
                    activeLink="View"
                />
                {!loading ? (
                    student && (
                        <div className="container-fluid">
                            <div className="col-lg-6">
                                <div className="card">
                                    <div className="card-header">
                                        <div>
                                            <h4 className="card-title">
                                                {student.name} <span>View</span>
                                            </h4>
                                            <p className="card-title-desc">
                                                <Link to="/students" className="btn btn-soft-light">
                                                    <i className="fa fa-angle-left"></i> Students
                                                </Link>
                                                <Link
                                                    to={`/students/${student._id}/edit`}
                                                    className="btn btn-soft-light"
                                                >
                                                    <i className="fa fa-edit"></i>
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <SingleItem title="Name" value={student.name} />
                                        <SingleItem title="Age" value={student.age} />
                                        {student.featured_img && (
                                            <SingleItem title="Featured Image">
                                                <img
                                                    src={`${URI}${student.featured_img.url}`}
                                                    style={{
                                                        width: "200px",
                                                        height: "200px",
                                                        objectFit: "contain",
                                                    }}
                                                />
                                            </SingleItem>
                                        )}
                                        <SingleItem title="Description" value={renderHTML(student.description)} />
                                        <SingleItem title="Slug" value={student.slug} />


                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                ) : (
                    <div>
                        <Spinner />
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({ student: state.student });

const mapDispatchToProps = { getStudent };

export default connect(mapStateToProps, mapDispatchToProps)(ViewStudent);
