import React, { useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { getUpcomingProject } from "../../actions/upcoming_projects";
import Spinner from "../../layout/Spinner";
import SingleItem from "../../components/template/SingleItem";
import { Link } from "react-router-dom";
import { URI } from "../../constants/constants";
const ViewUpcomingProject = ({
  getUpcomingProject,
  match,
  upcoming_project: { loading, upcoming_project },
}) => {
  useEffect(() => {
    getUpcomingProject(match.params.id);
  }, [match]);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title="Upcoming Project"
          mainLinkTitle="Upcoming Projects"
          mainLinkUrl="/upcoming_projects"
          activeLink="View"
        />
        {!loading ? (
          upcoming_project && (
            <div className="container-fluid">
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        {upcoming_project.title} <span>View</span>
                      </h4>
                      <p className="card-title-desc">
                        <Link
                          to="/upcoming_projects"
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-angle-left"></i> Projects
                        </Link>
                        <Link
                          to={`/upcoming_projects/${upcoming_project._id}/edit`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-edit"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="card-body">
                    <SingleItem title="title" value={upcoming_project.title} />
                    <SingleItem title="goal" value={upcoming_project.goal} />
                    <SingleItem
                      title="raised"
                      value={upcoming_project.achieved}
                    />
                    <SingleItem
                      title="description"
                      value={upcoming_project.description}
                    />
                    {upcoming_project.featured_image && (
                      <SingleItem title="Featured Image">
                        <img
                          src={`${URI}${upcoming_project.featured_image.url}`}
                          style={{
                            width: "200px",
                            height: "200px",
                            objectFit: "contain",
                          }}
                        />
                      </SingleItem>
                    )}
                    {upcoming_project.gallery &&
                      upcoming_project.gallery.length > 0 && (
                        <SingleItem title="Gallery">
                          <div className="d-flex" style={{ flexWrap: "wrap" }}>
                            {upcoming_project.gallery.map((item) => {
                              return (
                                <img
                                  src={`${URI}${item.url}`}
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    objectFit: "contain",
                                  }}
                                />
                              );
                            })}
                          </div>
                        </SingleItem>
                      )}
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  upcoming_project: state.upcoming_project,
});

const mapDispatchToProps = { getUpcomingProject };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewUpcomingProject);
