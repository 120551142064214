import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Form, Formik } from "formik";
import {
  getUpcomingProject,
  editUpcomingProject,
} from "../../actions/upcoming_projects";
import EditorWYSIWYG from "../../components/EditorWYSIWYG";
import * as Yup from "yup";
import {
  CheckBox,
  SelectBox,
  TextArea,
  TextInput,
} from "../../components/Form/Form";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Spinner from "../../layout/Spinner";
import slugify from "react-slugify";
const EditUpcomingProject = ({
  editUpcomingProject,
  getUpcomingProject,
  alert,
  match,
  upcoming_project: { loading, upcoming_project },
}) => {
  let history = useHistory();
  useEffect(() => {
    getUpcomingProject(match.params.id);
  }, [match]);
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title="Edit Upcoming Project"
          mainLinkTitle="Upcoming Projects"
          mainLinkUrl="/upcoming_projects"
          activeLink="Edit"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            {!loading ? (
              upcoming_project && (
                <div>
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        {upcoming_project.title} - <span>Edit</span>{" "}
                      </h4>
                      <p className="card-title-desc">
                        <Link
                          to="/upcoming_projects"
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-angle-left"></i> UpcomingProjects
                        </Link>
                        <Link
                          to={`/upcoming_projects/${upcoming_project._id}/view`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <Formik
                        initialValues={{
                          title: upcoming_project.title,
                          goal: upcoming_project.goal,
                          achieved: upcoming_project.achieved,
                          description: upcoming_project.description,
                          slug: upcoming_project.slug,
                        }}
                        validationSchema={Yup.object({
                          title: Yup.string().required("Required"),
                          goal: Yup.string().required("Required"),
                          achieved: Yup.string().required("Required"),
                          description: Yup.string().required("Required"),
                          slug: Yup.string().required("Required"),
                        })}
                        onSubmit={async (
                          values,
                          { setSubmitting, resetForm }
                        ) => {
                          setSubmitting(true);
                          const newForm = new FormData();
                          if (featuredImage) {
                            newForm.append(
                              `files.featured_image`,
                              featuredImage,
                              featuredImage.name
                            );
                          }
                          if (gallery) {
                            Array.from(gallery).forEach((image, index) => {
                              console.log("IMAGE", image);
                              newForm.append(
                                `files.gallery`,
                                image,
                                image.name
                              );
                            });
                          }
                          newForm.append("data", JSON.stringify(values));
                          await editUpcomingProject(
                            upcoming_project._id,
                            newForm
                          );
                          resetForm();
                          setSubmitting(false);
                          history.push(
                            `/upcoming_projects/${upcoming_project._id}/view`
                          );
                        }}
                      >
                        {(formik) => {
                          console.log(formik);
                          return (
                            <Form autoComplete={"off"}>
                              <div className="row">
                                <div className="col-md-6">
                                  <TextInput
                                    label="Title"
                                    name="title"
                                    type="text"
                                    placeholder="Enter Title"
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        "title",
                                        e.target.value
                                      );
                                      formik.setFieldValue(
                                        "slug",
                                        slugify(e.target.value)
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="Goal"
                                    name="goal"
                                    type="number"
                                    placeholder="Enter Goal"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="Raised"
                                    name="achieved"
                                    type="number"
                                    placeholder="Enter Raised"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <label> Featured Image </label>
                                  <br />
                                  <input
                                    type="file"
                                    className="form-control-file"
                                    onChange={(e) =>
                                      setFeaturedImage(e.target.files[0])
                                    }
                                  />
                                </div>
                                <div className="col-md-6">
                                  <label> Gallery </label>
                                  <br />
                                  <input
                                    type="file"
                                    multiple
                                    className="form-control-file"
                                    onChange={(e) => setGallery(e.target.files)}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <label>Slug</label>
                                  <input
                                    label="Slug"
                                    name="slug"
                                    type="text"
                                    placeholder="Enter Slug"
                                    className="form-control"
                                    value={formik.values.slug}
                                    onChange={(e) =>
                                      formik.setFieldValue(
                                        "slug",
                                        slugify(e.target.value)
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-md-12">
                                  <label> Description </label>
                                  <EditorWYSIWYG
                                    value={formik.values.description}
                                    changeValue={(value) => {
                                      formik.setFieldValue(
                                        `description`,
                                        value
                                      );
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-12 text-center m-3">
                                  <button
                                    type="submit"
                                    className="btn btn-success"
                                  >
                                    {formik.isSubmitting
                                      ? "Processing..."
                                      : "Save"}
                                  </button>
                                  <button
                                    className="btn btn-secondary m-3"
                                    onClick={history.goBack}
                                  >
                                    <i className="fa fa-angle-left"></i> Go Back
                                  </button>
                                </div>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              )
            ) : (
              <div className="text-center">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
  upcoming_project: state.upcoming_project,
});

const mapDispatchToProps = { editUpcomingProject, getUpcomingProject };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUpcomingProject);
