import api from "../utils/api";
import {
    GET_STUDENTS,
    TOTAL_STUDENTS,
    ADD_STUDENT,
    GET_STUDENT,
    RESET_STUDENT,
    STUDENTS_ERROR,
} from "./types";

import { setAlert } from "./alert";
// Get posts
export const countStudent =
    ({ q, createdAt_lte, createdAt_gte }) => async (dispatch) => {
        try {
            let query = "/students/count?";
            if (q) {
                let newQ = ``;
                newQ += `&_q=${q}`;
                query = query + newQ;
            }
            if (createdAt_lte) {
                let newCreatedAt_gte = ``;
                newCreatedAt_gte += `&createdAt_lte=${createdAt_lte}`;
                query = query + newCreatedAt_gte;
            }
            if (createdAt_gte) {
                let newCreatedAt_gte = ``;
                newCreatedAt_gte += `&createdAt_gte=${createdAt_gte}`;
                query = query + newCreatedAt_gte;
            }

            let res = await api.get(query);
            dispatch({
                type: TOTAL_STUDENTS,
                payload: res.data,
            });
        } catch (err) {
            dispatch({
                type: STUDENTS_ERROR,
                payload: err,
            });
            if (err.response && err.response.data) {
                const errors = err.response.data.message;
                if (errors && errors.length > 0) {
                    const mainError = errors[0];
                    if (mainError.messages) {
                        mainError.messages.forEach((error) =>
                            dispatch(setAlert(error.message, "danger"))
                        );
                    }
                }
            }
        }
    };

// Get posts
export const getStudents =
    ({ limit = 10, page = 1, sort = "DESC", q, createdAt_lte, createdAt_gte }) =>
        async (dispatch) => {
            try {
                dispatch({
                    type: RESET_STUDENT,
                });
                if (page) {
                    const start = (page - 1) * limit;
                    let query = `/students?_limit=${limit}&_start=${start}&_sort=createdAt:${sort}`;
                    if (q) {
                        const newQ = `&_q=${q}`;
                        query = query + newQ;
                    }
                    if (createdAt_lte) {
                        const newLTE = `&createdAt_lte=${createdAt_lte}`;
                        query = query + newLTE;
                    }
                    if (createdAt_gte) {
                        const newGTE = `&createdAt_gte=${createdAt_gte}`;
                        query = query + newGTE;
                    }

                    const res = await api.get(query);

                    dispatch({
                        type: GET_STUDENTS,
                        payload: res.data,
                    });
                }
            } catch (err) {
                dispatch({
                    type: STUDENTS_ERROR,
                    payload: err,
                });
                if (err.response && err.response.data) {
                    const errors = err.response.data.message;
                    if (errors && errors.length > 0) {
                        const mainError = errors[0];
                        if (mainError.messages) {
                            mainError.messages.forEach((error) =>
                                dispatch(setAlert(error.message, "danger"))
                            );
                        }
                    }
                }
            }
        };
export const getStudentBySlug = (id) => async (dispatch) => {
    try {
        dispatch({
            type: RESET_STUDENT,
        });
        const res = await api.get("/students?slug=" + id);
        if (res.data && res.data.length > 0) {
            dispatch({
                type: GET_STUDENT,
                payload: res.data[0],
            });
        } else {
            dispatch(setAlert("No Student Found", "danger"));
        }
    } catch (err) {
        dispatch({
            type: STUDENTS_ERROR,
            payload: err,
        });
        if (err.response && err.response.data) {
            const errors = err.response.data.message;
            if (errors && errors.length > 0) {
                const mainError = errors[0];
                if (mainError.messages) {
                    mainError.messages.forEach((error) =>
                        dispatch(setAlert(error.message, "danger"))
                    );
                }
            }
        }
    }
};
// Get posts
export const getStudent = (id) => async (dispatch) => {
    try {
        dispatch({
            type: RESET_STUDENT,
        });
        const res = await api.get("/students/" + id);

        dispatch({
            type: GET_STUDENT,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: STUDENTS_ERROR,
            payload: err,
        });
        if (err.response && err.response.data) {
            const errors = err.response.data.message;
            if (errors && errors.length > 0) {
                const mainError = errors[0];
                if (mainError.messages) {
                    mainError.messages.forEach((error) =>
                        dispatch(setAlert(error.message, "danger"))
                    );
                }
            }
        }
    }
};

export const addStudent = (data, history) => async (dispatch) => {
    try {
        const res = await api.post(`/students`, data);

        dispatch(setAlert("Student Saved!", "success"));
        dispatch({
            type: ADD_STUDENT,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: STUDENTS_ERROR,
            payload: err,
        });
        if (err.response && err.response.data) {
            const errors = err.response.data.message;
            if (errors && errors.length > 0) {
                const mainError = errors[0];
                if (mainError.messages) {
                    mainError.messages.forEach((error) =>
                        dispatch(setAlert(error.message, "danger"))
                    );
                }
            }
        }
    }
};
export const editStudent = (id, data, history) => async (dispatch) => {
    try {
        const res = await api.put("/students/" + id, data);

        dispatch(setAlert("Student Updated!", "success"));

    } catch (err) {
        dispatch({
            type: STUDENTS_ERROR,
            payload: err,
        });
        if (err.response && err.response.data) {
            const errors = err.response.data.message;
            if (errors && errors.length > 0) {
                const mainError = errors[0];
                if (mainError.messages) {
                    mainError.messages.forEach((error) =>
                        dispatch(setAlert(error.message, "danger"))
                    );
                }
            }
        }
    }
};

export const deleteStudent = (id) => async (dispatch) => {
    try {
        dispatch({
            type: RESET_STUDENT,
        });
        const res = await api.delete("/students/" + id);

        dispatch(setAlert("Student Deleted!", "success"));
    } catch (err) {
        dispatch({
            type: STUDENTS_ERROR,
            payload: err,
        });
        if (err.response && err.response.data) {
            const errors = err.response.data.message;
            if (errors && errors.length > 0) {
                const mainError = errors[0];
                if (mainError.messages) {
                    mainError.messages.forEach((error) =>
                        dispatch(setAlert(error.message, "danger"))
                    );
                }
            }
        }
    }
};          