import React, { useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { getVolunteer } from "../../actions/volunteers";
import Spinner from "../../layout/Spinner";
import SingleItem from "../../components/template/SingleItem";
import { Link } from "react-router-dom";
const ViewVolunteer = ({
  getVolunteer,
  match,
  volunteer: { loading, volunteer },
}) => {
  useEffect(() => {
    getVolunteer(match.params.id);
  }, [match]);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title="Volunteers"
          mainLinkTitle="Volunteers"
          mainLinkUrl="/volunteers"
          activeLink="View"
        />
        {!loading ? (
          volunteer && (
            <div className="container-fluid">
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        {volunteer.name} <span>View</span>
                      </h4>
                      <p className="card-title-desc">
                        <Link to="/volunteers" className="btn btn-soft-light">
                          <i className="fa fa-angle-left"></i> Volunteers
                        </Link>
                        <Link
                          to={`/volunteers/${volunteer._id}/edit`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-edit"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="card-body">
                    <SingleItem title="name" value={volunteer.name} />
                    <SingleItem title="email" value={volunteer.email} />
                    <SingleItem title="phone" value={volunteer.phone} />
                    <SingleItem title="address" value={volunteer.address} />
                    <SingleItem title="city" value={volunteer.city} />
                    <SingleItem title="state" value={volunteer.state} />
                    <SingleItem title="pin" value={volunteer.pin} />
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ volunteer: state.volunteer });

const mapDispatchToProps = { getVolunteer };

export default connect(mapStateToProps, mapDispatchToProps)(ViewVolunteer);
