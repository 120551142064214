import React, { useState } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Form, Formik } from "formik";
import EditorWYSIWYG from "../../components/EditorWYSIWYG";
import * as Yup from "yup";
import {
  CheckBox,
  SelectBox,
  TextArea,
  TextInput,
} from "../../components/Form/Form";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { addBlog } from "../../actions/blogs";
import slugify from "react-slugify";
const AddBlog = ({ addBlog, alert }) => {
  let history = useHistory();
  const [featuredImage, setFeaturedImage] = useState(null);

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title="Add blog"
          mainLinkTitle="Blogs"
          mainLinkUrl="/blogs"
          activeLink="Add"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Blog</h4>
              <p className="card-title-desc">Enter Details to add Blog</p>
            </div>
            <div className="card-body">
              <div className="row">
                <Formik
                  initialValues={{
                    title: "",
                    content: "",
                    meta_title: "",
                    meta_description: "",
                    slug: "",
                  }}
                  validationSchema={Yup.object({
                    title: Yup.string().required("Required"),
                    content: Yup.string().required("Required"),
                    slug: Yup.string().required("Required"),
                  })}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    const newForm = new FormData();
                    if (featuredImage) {
                      newForm.append(
                        `files.featured_image`,
                        featuredImage,
                        featuredImage.name
                      );
                    }
                    newForm.append("data", JSON.stringify(values));
                    await addBlog(newForm);
                    resetForm();
                    setSubmitting(false);
                    history.push("/blogs");
                  }}
                >
                  {(formik) => {
                    return (
                      <Form autoComplete={"off"}>
                        <div className="row">
                          <div className="col-md-6">
                            <TextInput
                              label="Title"
                              name="title"
                              type="text"
                              placeholder="Enter Title"
                              onChange={(e) => {
                                formik.setFieldValue("title", e.target.value);
                                formik.setFieldValue(
                                  "slug",
                                  slugify(e.target.value)
                                );
                              }}
                            />
                          </div>
                          <div className="col-md-12">
                            <label> Content </label>
                            <EditorWYSIWYG
                              value={formik.values.content}
                              changeValue={(value) => {
                                formik.setFieldValue(`content`, value);
                              }}
                            />
                          </div>

                          <div className="col-md-6">
                            <label> Featured Image </label>
                            <br />
                            <input
                              type="file"
                              className="form-control-file"
                              onChange={(e) =>
                                setFeaturedImage(e.target.files[0])
                              }
                            />
                          </div>
                          <div className="col-md-6">
                            <TextInput
                              label="Meta Title"
                              name="meta_title"
                              type="text"
                              placeholder="Enter Meta Title"
                            />
                          </div>
                          <div className="col-md-6">
                            <TextArea
                              label="Meta Description"
                              name="meta_description"
                              placeholder="Enter Meta Description"
                            />
                          </div>
                          <div className="col-md-6">
                            <label>Slug</label>
                            <input
                              label="Slug"
                              name="slug"
                              type="text"
                              placeholder="Enter Slug"
                              className="form-control"
                              value={formik.values.slug}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  "slug",
                                  slugify(e.target.value)
                                )
                              }
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12 text-center m-3">
                            <button type="submit" className="btn btn-success">
                              {formik.isSubmitting ? "Processing..." : "Save"}
                            </button>
                            <button
                              className="btn btn-secondary m-3"
                              onClick={history.goBack}
                            >
                              <i className="fa fa-angle-left"></i> Go Back
                            </button>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ alert: state.alert });

const mapDispatchToProps = { addBlog };

export default connect(mapStateToProps, mapDispatchToProps)(AddBlog);
