import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Link } from "react-router-dom";
import Spinner from "../../layout/Spinner";
import { countEvent, getEvents, deleteEvent } from "../../actions/events";
import { useLocation } from "react-router-dom";
import Pagination from "../../components/Pagination";
const AllEvents = ({
  countEvent,
  getEvents,
  deleteEvent,
  history,
  event: { loading, events, total_events },
}) => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const [page, setPage] = useState(null);
  const [limit, setLimit] = useState(null);
  const [sort, setSort] = useState(null);
  const [q, setQ] = useState(null);
  const [createdAt_lte, setCreatedAt_lte] = useState(null);
  const [createdAt_gte, setCreatedAt_gte] = useState(null);
  const [term, setTerm] = useState(query.get("_q"));
  const [deleteEntry, setDeleteEntry] = useState(null);
  const [deleting, setDeleting] = useState(false);

  useEffect(async () => {
    if (deleteEntry) {
      setDeleting(true);
      await deleteEvent(deleteEntry);
      setDeleting(false);
    }
    const limit = query.get("_limit") ? query.get("_limit") : 10;
    const page = query.get("page") ? query.get("page") : 1;
    const sort = query.get("_sort") ? query.get("_sort") : "DESC";

    const q = query.get("_q");
    const createdAt_lte = query.get("createdAt_lte");
    const createdAt_gte = query.get("createdAt_gte");
    getEvents({ limit, page, sort, q, createdAt_lte, createdAt_gte });

    setPage(parseInt(page));
    setLimit(parseInt(limit));
    setSort(sort);
    setQ(q);
    setCreatedAt_lte(createdAt_lte);
    setCreatedAt_gte(createdAt_gte);
  }, [
    deleteEntry,
    query.get("_limit"),
    query.get("page"),
    query.get("_q"),
    query.get("_sort"),
    query.get("createdAt_lte"),
    query.get("createdAt_gte"),
  ]);
  useEffect(() => {
    const q = query.get("_q");
    const createdAt_lte = query.get("createdAt_lte");
    const createdAt_gte = query.get("createdAt_gte");
    countEvent({ q, createdAt_lte, createdAt_gte });
  }, [query.get("_q"), query.get("createdAt_lte"), query.get("createdAt_gte")]);

  const changeParams = ({ label, value }) => {
    query.set(`${label}`, value);
    history.push(`${window.location.pathname}?${query.toString()}`);
  };
  const removeParams = (label) => {
    query.delete(label);
    history.push(`${window.location.pathname}?${query.toString()}`);
  };
  const deleteEventClicked = (id) => {
    setDeleteEntry(id);
  };
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title="Events"
          mainLinkTitle="Dashboard"
          mainLinkUrl="/dashboard"
          activeLink="Main"
        />

        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="card"
                style={{ boxShadow: "rgb(227 233 243) 0px 2px 4px" }}
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm">
                      <div className="mb-4">
                        <Link
                          type="button"
                          to="/Events/add"
                          className="btn btn-light
                                  waves-effect waves-light"
                        >
                          <i className="fa fa-plus me-1" /> Add event
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="dataTables_wrapper dt-bootstrap4 no-footer"></div>
                    <div className="row">
                      <div
                        className="filters"
                        style={{
                          borderTop: "1px solid #f1f1f1",
                          borderBottom: "1px solid #f1f1f1",
                          padding: 10,
                          margin: 10,
                        }}
                      >
                        <div className="d-flex">
                          <div>
                            <i className="fa fa-filter" /> Active Filters
                          </div>
                          {q && (
                            <div
                              style={{
                                border: "1px solid rgba(253,98,94,.25)",
                                margin: "0px 10px",
                                padding: "5px 5px",
                                color: "rgba(253,98,94,1)",
                                backgroundColor: "#f1f1f1",
                              }}
                            >
                              Search Term:{" "}
                              <label style={{ paddingRight: "10px" }}>
                                {" "}
                                {q}{" "}
                              </label>
                              <button
                                className="btn-sm btn-close"
                                onClick={() => {
                                  setTerm(""), removeParams("_q");
                                }}
                                style={{
                                  fontSize: "12px",
                                  color: "rgba(253,98,94,1)",
                                }}
                              >
                                <i
                                  className="fa fa-close"
                                  style={{
                                    fontSize: "12px",
                                    color: "rgba(253,98,94,1)",
                                  }}
                                ></i>
                              </button>
                            </div>
                          )}
                          {createdAt_gte && (
                            <div
                              style={{
                                border: "1px solid rgba(253,98,94,.25)",
                                margin: "0px 10px",
                                padding: "5px 5px",
                                color: "rgba(253,98,94,1)",
                                backgroundColor: "#f1f1f1",
                              }}
                            >
                              From:{" "}
                              <label style={{ paddingRight: "10px" }}>
                                {" "}
                                {createdAt_gte}{" "}
                              </label>
                              <button
                                className="btn-sm btn-close"
                                onClick={() => {
                                  setCreatedAt_gte(""),
                                    removeParams("createdAt_gte");
                                }}
                                style={{
                                  fontSize: "12px",
                                  color: "rgba(253,98,94,1)",
                                }}
                              >
                                <i
                                  className="fa fa-close"
                                  style={{
                                    fontSize: "12px",
                                    color: "rgba(253,98,94,1)",
                                  }}
                                ></i>
                              </button>
                            </div>
                          )}
                          {createdAt_lte && (
                            <div
                              style={{
                                border: "1px solid rgba(253,98,94,.25)",
                                margin: "0px 10px",
                                padding: "5px 5px",
                                color: "rgba(253,98,94,1)",
                                backgroundColor: "#f1f1f1",
                              }}
                            >
                              To:{" "}
                              <label style={{ paddingRight: "10px" }}>
                                {" "}
                                {createdAt_lte}{" "}
                              </label>
                              <button
                                className="btn-sm btn-close"
                                onClick={() => {
                                  setCreatedAt_lte(""),
                                    removeParams("createdAt_lte");
                                }}
                                style={{
                                  fontSize: "12px",
                                  color: "rgba(253,98,94,1)",
                                }}
                              >
                                <i
                                  className="fa fa-close"
                                  style={{
                                    fontSize: "12px",
                                    color: "rgba(253,98,94,1)",
                                  }}
                                ></i>
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-9">
                        <div className="d-flex">
                          <div
                            className="dataTables_length"
                            id="DataTables_Table_0_length"
                          >
                            <label>
                              Show{" "}
                              <select
                                name="DataTables_Table_0_length"
                                aria-controls="DataTables_Table_0"
                                value={limit}
                                className="custom-select custom-select-sm form-control form-control-sm form-select form-select-sm"
                                onChange={(e) => {
                                  changeParams({
                                    label: "page",
                                    value: 1,
                                  });
                                  changeParams({
                                    label: "_limit",
                                    value: e.target.value,
                                  });
                                }}
                              >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>{" "}
                              entries
                            </label>
                          </div>
                          <div
                            style={{ paddingLeft: "20px" }}
                            className="dataTables_length"
                            id="DataTables_Table_0_length"
                          >
                            <label>
                              Sort
                              <select
                                name="DataTables_Table_0_length"
                                aria-controls="DataTables_Table_0"
                                value={sort}
                                className="custom-select custom-select-sm form-control form-control-sm form-select form-select-sm"
                                onChange={(e) => {
                                  changeParams({
                                    label: "_sort",
                                    value: e.target.value,
                                  });
                                }}
                              >
                                <option value={"DESC"}>Latest to Older</option>
                                <option value={"ASC"}>Older to Latest</option>
                              </select>{" "}
                              entries
                            </label>
                          </div>
                          <div
                            style={{ paddingLeft: "20px" }}
                            className="dataTables_length"
                            id="DataTables_Table_0_length"
                          >
                            <label>
                              From
                              <input
                                type="date"
                                value={createdAt_gte}
                                onChange={(e) => {
                                  changeParams({
                                    label: "page",
                                    value: 1,
                                  });
                                  changeParams({
                                    label: "createdAt_gte",
                                    value: e.target.value,
                                  });
                                }}
                                className="form-control"
                              />
                            </label>
                          </div>
                          <div
                            style={{ paddingLeft: "20px" }}
                            className="dataTables_length"
                            id="DataTables_Table_0_length"
                          >
                            <label>
                              To
                              <input
                                type="date"
                                className="form-control"
                                value={createdAt_lte}
                                onChange={(e) => {
                                  changeParams({
                                    label: "page",
                                    value: 1,
                                  });
                                  changeParams({
                                    label: "createdAt_lte",
                                    value: e.target.value,
                                  });
                                }}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-3 ">
                        <div
                          id="DataTables_Table_0_filter"
                          className="dataTables_filter"
                        >
                          <div className="d-flex" style={{ float: "right" }}>
                            <div>
                              <input
                                type="search"
                                className="form-control form-control-sm"
                                aria-controls="DataTables_Table_0"
                                value={term}
                                onChange={(e) => setTerm(e.target.value)}
                              />
                            </div>
                            <div style={{ padding: "0px 10px" }}>
                              <button
                                type="submit"
                                className="btn btn-soft-light"
                                onClick={() => {
                                  changeParams({
                                    label: "page",
                                    value: 1,
                                  });
                                  changeParams({
                                    label: "_q",
                                    value: term,
                                  });
                                }}
                              >
                                <i className="fa fa-search"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <table className="table align-middle datatable dt-responsive table-check nowrap table-striped dataTable no-footer">
                          <thead>
                            <tr className="bg-transparent" role="row">
                              <th>Sr No</th>
                              <th> Name </th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!loading ? (
                              events &&
                              events.map((event, index) => {
                                return (
                                  <tr key={`event-${index}`}>
                                    <td className="sorting_1">
                                      {index + 1 + (page - 1) * limit}{" "}
                                    </td>
                                    <td> {event.name} </td>
                                    <td>
                                      <Link
                                        to={`/events/${event._id}/view`}
                                        className="btn "
                                      >
                                        <i className="fa fa-eye"></i>
                                      </Link>
                                      <Link
                                        to={`/events/${event._id}/edit`}
                                        className="btn"
                                      >
                                        <i className="fa fa-edit"></i>
                                      </Link>
                                      <button
                                        onClick={() =>
                                          deleteEventClicked(event._id)
                                        }
                                        className="btn text-danger"
                                      >
                                        <i className="fa fa-trash"></i>
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={20} className="text-center">
                                  <Spinner />
                                </td>
                              </tr>
                            )}
                            {events && events.length === 0 && (
                              <tr>
                                <td colSpan={20}>No result found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {!loading ? (
                      events && (
                        <div className="row">
                          <div className="col-sm-12 col-md-5">
                            <div
                              className="dataTables_info"
                              id="DataTables_Table_0_info"
                              role="status"
                              aria-live="polite"
                            >
                              Total {total_events} entries Found
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-7">
                            {total_events && total_events > 0 && (
                              <Pagination
                                total={total_events}
                                current_page={page}
                                limit={limit ? limit : 10}
                                changePage={(page) =>
                                  changeParams({
                                    label: "page",
                                    value: page,
                                  })
                                }
                              />
                            )}
                          </div>
                        </div>
                      )
                    ) : (
                      <div>..</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ event: state.event });

const mapDispatchToProps = { countEvent, getEvents, deleteEvent };

export default connect(mapStateToProps, mapDispatchToProps)(AllEvents);
