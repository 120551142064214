import React, { useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Form, Formik } from "formik";

import * as Yup from "yup";
import {
  CheckBox,
  SelectBox,
  TextArea,
  TextInput,
} from "../../components/Form/Form";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { addContact } from "../../actions/contacts";

const AddContact = ({ addContact, alert }) => {
  let history = useHistory();

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title="Add contact"
          mainLinkTitle="Contacts"
          mainLinkUrl="/contacts"
          activeLink="Add"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Contact</h4>
              <p className="card-title-desc">Enter Details to add Contact</p>
            </div>
            <div className="card-body">
              <div className="row">
                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                    phone: "",
                    subject: "",
                    message: "",
                  }}
                  validationSchema={Yup.object({
                    name: Yup.string().required("Required"),
                  })}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    await addContact(values);
                    resetForm();
                    setSubmitting(false);
                    history.push("/contacts");
                  }}
                >
                  {(formik) => {
                    return (
                      <Form autoComplete={"off"}>
                        <div className="row">
                          <div className="col-md-6">
                            <TextInput
                              label="Name"
                              name="name"
                              type="text"
                              placeholder="Enter Name"
                            />
                          </div>
                          <div className="col-md-6">
                            <TextInput
                              label="Email"
                              name="email"
                              type="email"
                              placeholder="Enter Email"
                            />
                          </div>
                          <div className="col-md-6">
                            <TextInput
                              label="Phone"
                              name="phone"
                              type="text"
                              placeholder="Enter Phone"
                            />
                          </div>
                          <div className="col-md-6">
                            <TextInput
                              label="Subject"
                              name="subject"
                              type="text"
                              placeholder="Enter Subject"
                            />
                          </div>
                          <div className="col-md-6">
                            <TextInput
                              label="Message"
                              name="message"
                              type="text"
                              placeholder="Enter Message"
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12 text-center m-3">
                            <button type="submit" className="btn btn-success">
                              {formik.isSubmitting ? "Processing..." : "Save"}
                            </button>
                            <button
                              className="btn btn-secondary m-3"
                              onClick={history.goBack}
                            >
                              <i className="fa fa-angle-left"></i> Go Back
                            </button>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ alert: state.alert });

const mapDispatchToProps = { addContact };

export default connect(mapStateToProps, mapDispatchToProps)(AddContact);
