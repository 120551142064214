import React, { useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Form, Formik } from "formik";
import { getContact, editContact } from "../../actions/contacts";
import * as Yup from "yup";
import {
  CheckBox,
  SelectBox,
  TextArea,
  TextInput,
} from "../../components/Form/Form";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Spinner from "../../layout/Spinner";

const EditContact = ({
  editContact,
  getContact,
  alert,
  match,
  contact: { loading, contact },
}) => {
  let history = useHistory();
  useEffect(() => {
    getContact(match.params.id);
  }, [match]);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title="Edit Contact"
          mainLinkTitle="Contacts"
          mainLinkUrl="/contacts"
          activeLink="Edit"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            {!loading ? (
              contact && (
                <div>
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        {contact.name} - <span>Edit</span>{" "}
                      </h4>
                      <p className="card-title-desc">
                        <Link to="/contacts" className="btn btn-soft-light">
                          <i className="fa fa-angle-left"></i> Contacts
                        </Link>
                        <Link
                          to={`/contacts/${contact._id}/view`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <Formik
                        initialValues={{
                          name: contact.name,
                          email: contact.email,
                          phone: contact.phone,
                          subject: contact.subject,
                          message: contact.message,
                        }}
                        validationSchema={Yup.object({
                          name: Yup.string().required("Required"),
                        })}
                        onSubmit={async (
                          values,
                          { setSubmitting, resetForm }
                        ) => {
                          setSubmitting(true);
                          await editContact(contact._id, values);
                          resetForm();
                          setSubmitting(false);
                          history.push(`/contacts/${contact._id}/view`);
                        }}
                      >
                        {(formik) => {
                          console.log(formik);
                          return (
                            <Form autoComplete={"off"}>
                              <div className="row">
                                <div className="col-md-6">
                                  <TextInput
                                    label="Name"
                                    name="name"
                                    type="text"
                                    placeholder="Enter Name"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="Email"
                                    name="email"
                                    type="email"
                                    placeholder="Enter Email"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="Phone"
                                    name="phone"
                                    type="text"
                                    placeholder="Enter Phone"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="Subject"
                                    name="subject"
                                    type="text"
                                    placeholder="Enter Subject"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="Message"
                                    name="message"
                                    type="text"
                                    placeholder="Enter Message"
                                  />
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-12 text-center m-3">
                                  <button
                                    type="submit"
                                    className="btn btn-success"
                                  >
                                    {formik.isSubmitting
                                      ? "Processing..."
                                      : "Save"}
                                  </button>
                                  <button
                                    className="btn btn-secondary m-3"
                                    onClick={history.goBack}
                                  >
                                    <i className="fa fa-angle-left"></i> Go Back
                                  </button>
                                </div>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              )
            ) : (
              <div className="text-center">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
  contact: state.contact,
});

const mapDispatchToProps = { editContact, getContact };

export default connect(mapStateToProps, mapDispatchToProps)(EditContact);
