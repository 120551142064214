import React, { useState } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Form, Formik } from "formik";
import EditorWYSIWYG from "../../components/EditorWYSIWYG";
import * as Yup from "yup";
import {
  CheckBox,
  SelectBox,
  TextArea,
  TextInput,
} from "../../components/Form/Form";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { addCause } from "../../actions/causes";
import slugify from "react-slugify";
const AddCause = ({ addCause, alert }) => {
  let history = useHistory();
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title="Add Cause"
          mainLinkTitle="Causes"
          mainLinkUrl="/causes"
          activeLink="Add"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Cause</h4>
              <p className="card-title-desc">Enter Details to add Cause</p>
            </div>
            <div className="card-body">
              <div className="row">
                <Formik
                  initialValues={{
                    name: "",
                    description: "",
                    goal: "",
                    achieved: "",
                    slug: "",
                    disease: "",
                  }}
                  validationSchema={Yup.object({
                    name: Yup.string().required("Required"),
                    description: Yup.string().required("Required"),
                    goal: Yup.string().required("Required"),
                    achieved: Yup.string().required("Required"),
                    slug: Yup.string().required("Required"),
                    disease: Yup.string().required("Required"),
                  })}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    const newForm = new FormData();
                    if (featuredImage) {
                      newForm.append(
                        `files.featured_image`,
                        featuredImage,
                        featuredImage.name
                      );
                    }
                    if (gallery) {
                      Array.from(gallery).forEach((image, index) => {
                        console.log("IMAGE", image);
                        newForm.append(`files.gallery`, image, image.name);
                      });
                    }
                    newForm.append("data", JSON.stringify(values));
                    await addCause(newForm);
                    resetForm();
                    setSubmitting(false);
                    history.push("/causes");
                  }}
                >
                  {(formik) => {
                    return (
                      <Form autoComplete={"off"}>
                        <div className="row">
                          <div className="col-md-6">
                            <TextInput
                              label="Name"
                              name="name"
                              type="text"
                              placeholder="Enter Name"
                              onChange={(e) => {
                                formik.setFieldValue("name", e.target.value);
                                formik.setFieldValue(
                                  "slug",
                                  slugify(e.target.value)
                                );
                              }}
                            />
                          </div>
                          <div className="col-md-6">
                            <TextInput
                              label="Goal"
                              name="goal"
                              type="number"
                              placeholder="Enter Goal"
                            />
                          </div>
                          <div className="col-md-6">
                            <TextInput
                              label="Achieved"
                              name="achieved"
                              type="number"
                              placeholder="Enter Achieved"
                            />
                          </div>
                          <div className="col-md-6">
                            <TextInput
                              label="Achieved"
                              name="achieved"
                              type="number"
                              placeholder="Enter Achieved"
                            />
                          </div>
                          <div className="col-md-6">
                            <TextInput
                              label="Disease"
                              name="disease"
                              type="text"
                              placeholder="Enter Disease"
                            />
                          </div>
                          <div className="col-md-6">
                            <label> Featured Image </label>
                            <br />
                            <input
                              type="file"
                              className="form-control-file"
                              onChange={(e) =>
                                setFeaturedImage(e.target.files[0])
                              }
                            />
                          </div>
                          <div className="col-md-6">
                            <label> Gallery </label>
                            <br />
                            <input
                              type="file"
                              multiple
                              className="form-control-file"
                              onChange={(e) => setGallery(e.target.files)}
                            />
                          </div>
                          <div className="col-md-6">
                            <label>Slug</label>
                            <input
                              label="Slug"
                              name="slug"
                              type="text"
                              placeholder="Enter Slug"
                              className="form-control"
                              value={formik.values.slug}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  "slug",
                                  slugify(e.target.value)
                                )
                              }
                            />
                          </div>
                          <div className="col-md-12">
                            <label> Description </label>
                            <EditorWYSIWYG
                              value={formik.values.description}
                              changeValue={(value) => {
                                formik.setFieldValue(`description`, value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 text-center m-3">
                            <button type="submit" className="btn btn-success">
                              {formik.isSubmitting ? "Processing..." : "Save"}
                            </button>
                            <button
                              className="btn btn-secondary m-3"
                              onClick={history.goBack}
                            >
                              <i className="fa fa-angle-left"></i> Go Back
                            </button>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ alert: state.alert });

const mapDispatchToProps = { addCause };

export default connect(mapStateToProps, mapDispatchToProps)(AddCause);
