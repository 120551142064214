import React, { useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Form, Formik } from "formik";
import { getFaq, editFaq } from "../../actions/faq";
import * as Yup from "yup";
import {
  CheckBox,
  SelectBox,
  TextArea,
  TextInput,
} from "../../components/Form/Form";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Spinner from "../../layout/Spinner";

const EditFaq = ({ editFaq, getFaq, alert, match, faq: { loading, faq } }) => {
  let history = useHistory();
  useEffect(() => {
    getFaq(match.params.id);
  }, [match]);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title="Edit Faq"
          mainLinkTitle="Faqs"
          mainLinkUrl="/faqs"
          activeLink="Edit"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            {!loading ? (
              faq && (
                <div>
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        {faq.name} - <span>Edit</span>{" "}
                      </h4>
                      <p className="card-title-desc">
                        <Link to="/faqs" className="btn btn-soft-light">
                          <i className="fa fa-angle-left"></i> Faqs
                        </Link>
                        <Link
                          to={`/faqs/${faq._id}/view`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <Formik
                        initialValues={{
                          question: faq.question,
                          answer: faq.answer,
                        }}
                        validationSchema={Yup.object({
                          question: Yup.string().required("Required"),
                          answer: Yup.string().required("Required"),
                        })}
                        onSubmit={async (
                          values,
                          { setSubmitting, resetForm }
                        ) => {
                          setSubmitting(true);
                          await editFaq(faq._id, values);
                          resetForm();
                          setSubmitting(false);
                          history.push(`/faqs/${faq._id}/view`);
                        }}
                      >
                        {(formik) => {
                          console.log(formik);
                          return (
                            <Form autoComplete={"off"}>
                              <div className="row">
                                <div className="col-md-6">
                                  <TextInput
                                    label="Question"
                                    name="question"
                                    type="string"
                                    placeholder="Enter Question"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="Answer"
                                    name="answer"
                                    type="text"
                                    placeholder="Enter Answer"
                                  />
                                </div>
                                <div className="col-md-12 text-center m-3">
                                  <button
                                    type="submit"
                                    className="btn btn-success"
                                  >
                                    {formik.isSubmitting
                                      ? "Processing..."
                                      : "Save"}
                                  </button>
                                  <a
                                    className="btn btn-secondary m-3"
                                    onClick={() => history.goBack()}
                                  >
                                    <i className="fa fa-angle-left"></i> Go Back
                                  </a>
                                </div>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              )
            ) : (
              <div className="text-center">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
  faq: state.faq,
});

const mapDispatchToProps = { editFaq, getFaq };

export default connect(mapStateToProps, mapDispatchToProps)(EditFaq);
