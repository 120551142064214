import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Form, Formik } from "formik";
import { getEvent, editEvent } from "../../actions/events";
import * as Yup from "yup";
import {
  CheckBox,
  SelectBox,
  TextArea,
  TextInput,
} from "../../components/Form/Form";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Spinner from "../../layout/Spinner";
import slugify from "react-slugify";
const EditEvent = ({
  editEvent,
  getEvent,
  alert,
  match,
  event: { loading, event },
}) => {
  let history = useHistory();
  useEffect(() => {
    getEvent(match.params.id);
  }, [match]);
  const [gallery, setGallery] = useState(null);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title="Edit Event"
          mainLinkTitle="Events"
          mainLinkUrl="/events"
          activeLink="Edit"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            {!loading ? (
              event && (
                <div>
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        {event.name} - <span>Edit</span>{" "}
                      </h4>
                      <p className="card-title-desc">
                        <Link to="/events" className="btn btn-soft-light">
                          <i className="fa fa-angle-left"></i> Events
                        </Link>
                        <Link
                          to={`/events/${event._id}/view`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <Formik
                        initialValues={{
                          name: event.name,
                          slug: event.slug,
                        }}
                        validationSchema={Yup.object({
                          name: Yup.string().required("Required"),
                          slug: Yup.string().required("Required"),
                        })}
                        onSubmit={async (
                          values,
                          { setSubmitting, resetForm }
                        ) => {
                          setSubmitting(true);
                          const newForm = new FormData();

                          if (gallery) {
                            Array.from(gallery).forEach((image, index) => {
                              console.log("IMAGE", image);
                              newForm.append(
                                `files.gallery`,
                                image,
                                image.name
                              );
                            });
                          }
                          newForm.append("data", JSON.stringify(values));
                          await editEvent(event._id, newForm);
                          resetForm();
                          setSubmitting(false);
                          history.push(`/events/${event._id}/view`);
                        }}
                      >
                        {(formik) => {
                          console.log(formik);
                          return (
                            <Form autoComplete={"off"}>
                              <div className="row">
                                <div className="col-md-6">
                                  <TextInput
                                    label="Name"
                                    name="name"
                                    type="text"
                                    placeholder="Enter Name"
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        "name",
                                        e.target.value
                                      );
                                      formik.setFieldValue(
                                        "slug",
                                        slugify(e.target.value)
                                      );
                                    }}
                                  />
                                </div>

                                <div className="col-md-6">
                                  <label> Gallery </label>
                                  <br />
                                  <input
                                    type="file"
                                    multiple
                                    className="form-control-file"
                                    onChange={(e) => setGallery(e.target.files)}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <label>Slug</label>
                                  <input
                                    label="Slug"
                                    name="slug"
                                    type="text"
                                    placeholder="Enter Slug"
                                    className="form-control"
                                    value={formik.values.slug}
                                    onChange={(e) =>
                                      formik.setFieldValue(
                                        "slug",
                                        slugify(e.target.value)
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12 text-center m-3">
                                  <button
                                    type="submit"
                                    className="btn btn-success"
                                  >
                                    {formik.isSubmitting
                                      ? "Processing..."
                                      : "Save"}
                                  </button>
                                  <button
                                    className="btn btn-secondary m-3"
                                    onClick={history.goBack}
                                  >
                                    <i className="fa fa-angle-left"></i> Go Back
                                  </button>
                                </div>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              )
            ) : (
              <div className="text-center">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
  event: state.event,
});

const mapDispatchToProps = { editEvent, getEvent };

export default connect(mapStateToProps, mapDispatchToProps)(EditEvent);
