import React, { useState } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Form, Formik } from "formik";

import * as Yup from "yup";
import {
  CheckBox,
  SelectBox,
  TextArea,
  TextInput,
} from "../../components/Form/Form";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { addTestimonial } from "../../actions/testimonials";

const AddTestimonial = ({ addTestimonial, alert }) => {
  let history = useHistory();
  const [featuredImage, setFeaturedImage] = useState(null);

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title="Add Testimonial"
          mainLinkTitle="Testimonials"
          mainLinkUrl="/testimonials"
          activeLink="Add"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Testimonial</h4>
              <p className="card-title-desc">
                Enter Details to add Testimonial
              </p>
            </div>
            <div className="card-body">
              <div className="row">
                <Formik
                  initialValues={{
                    user: "",
                    testimonial: "",
                  }}
                  validationSchema={Yup.object({
                    user: Yup.string().required("Required"),
                    testimonial: Yup.string().required("Required"),
                  })}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    const newForm = new FormData();
                    if (featuredImage) {
                      newForm.append(
                        `files.user_image`,
                        featuredImage,
                        featuredImage.name
                      );
                    }

                    newForm.append("data", JSON.stringify(values));
                    await addTestimonial(newForm);
                    resetForm();
                    setSubmitting(false);
                    history.push("/testimonials");
                  }}
                >
                  {(formik) => {
                    return (
                      <Form autoComplete={"off"}>
                        <div className="row">
                          <div className="col-md-6">
                            <TextInput
                              label="User"
                              name="user"
                              type="text"
                              placeholder="Enter User"
                            />
                          </div>
                          <div className="col-md-6">
                            <TextArea
                              label="Testimonial"
                              name="testimonial"
                              placeholder="Enter Testimonial"
                            />
                          </div>
                          <div className="col-md-6">
                            <label> Featured Image </label>
                            <br />
                            <input
                              type="file"
                              className="form-control-file"
                              onChange={(e) =>
                                setFeaturedImage(e.target.files[0])
                              }
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 text-center m-3">
                            <button type="submit" className="btn btn-success">
                              {formik.isSubmitting ? "Processing..." : "Save"}
                            </button>
                            <button
                              className="btn btn-secondary m-3"
                              onClick={history.goBack}
                            >
                              <i className="fa fa-angle-left"></i> Go Back
                            </button>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ alert: state.alert });

const mapDispatchToProps = { addTestimonial };

export default connect(mapStateToProps, mapDispatchToProps)(AddTestimonial);
