import React, { useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { getCause } from "../../actions/causes";
import Spinner from "../../layout/Spinner";
import SingleItem from "../../components/template/SingleItem";
import { Link } from "react-router-dom";
import { URI } from "../../constants/constants";
const ViewCause = ({ getCause, match, cause: { loading, cause } }) => {
  useEffect(() => {
    getCause(match.params.id);
  }, [match]);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title="Causes"
          mainLinkTitle="Causes"
          mainLinkUrl="/causes"
          activeLink="View"
        />
        {!loading ? (
          cause && (
            <div className="container-fluid">
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        {cause.name} <span>View</span>
                      </h4>
                      <p className="card-title-desc">
                        <Link to="/causes" className="btn btn-soft-light">
                          <i className="fa fa-angle-left"></i> Causes
                        </Link>
                        <Link
                          to={`/causes/${cause._id}/edit`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-edit"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="card-body">
                    <SingleItem title="name" value={cause.name} />
                    <SingleItem title="goal" value={cause.goal} />
                    <SingleItem title="achieved" value={cause.achieved} />
                    <SingleItem title="Disease" value={cause.disease} />
                    <SingleItem title="description" value={cause.description} />
                    {cause.featured_image && (
                      <SingleItem title="Featured Image">
                        <img
                          src={`${URI}${cause.featured_image.url}`}
                          style={{
                            width: "200px",
                            height: "200px",
                            objectFit: "contain",
                          }}
                        />
                      </SingleItem>
                    )}
                    {cause.gallery && cause.gallery.length > 0 && (
                      <SingleItem title="Gallery">
                        <div className="d-flex" style={{ flexWrap: "wrap" }}>
                          {cause.gallery.map((item) => {
                            return (
                              <img
                                src={`${URI}${item.url}`}
                                style={{
                                  width: "80px",
                                  height: "80px",
                                  objectFit: "contain",
                                }}
                              />
                            );
                          })}
                        </div>
                      </SingleItem>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ cause: state.cause });

const mapDispatchToProps = { getCause };

export default connect(mapStateToProps, mapDispatchToProps)(ViewCause);
